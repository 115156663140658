import React, { createContext, useState, ReactNode, useMemo } from "react";

export interface PublicMpStepperData {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  mobileNumber: string;
  email: string;
  alternativeLastName?: string;
  zipCode?: string;
  middleName?: string;
}

export type PublicMpFailedErrorType =
  | "MP_ACTIVE_ALREADY_EXISTS"
  | "MP_NO_MATCHING_ORDER"
  | "MP_NO_PATIENT_MATCH"
  | "MP_NO_ORDER_FOUND"
  | "NO_SIGNED_ORDER"
  | "MP_GENERIC_NO_MATCH_ERROR"; // Generic MP no-match/service/network error

export interface PublicMpStepperDataController {
  publicMpStepperData?: PublicMpStepperData;
  savePublicMpStepperData: (data: PublicMpStepperData) => void;
  publicMpFailedErrorType?: PublicMpFailedErrorType;
  setPublicMpFailedErrorType: (errorType: PublicMpFailedErrorType) => void;
  publicMpToken?: string;
  setPublicMpToken: (token: string) => void;
  publicMpCode?: string;
  setPublicMpCode: (code: string) => void;
  recaptchaValue: string | null;
  setRecaptchaValue: (token: string | null) => void;
}

const defaultPublicMpStepperDataController: PublicMpStepperDataController = {
  publicMpStepperData: undefined,
  savePublicMpStepperData: () => undefined,
  publicMpFailedErrorType: undefined,
  setPublicMpFailedErrorType: () => undefined,
  publicMpToken: undefined,
  setPublicMpToken: () => undefined,
  publicMpCode: undefined,
  setPublicMpCode: () => undefined,
  recaptchaValue: null,
  setRecaptchaValue: () => undefined,
};

export const Context = createContext<PublicMpStepperDataController>(
  defaultPublicMpStepperDataController
);

Context.displayName = "PublicMpStepperDataContext";

interface PublicMpStepperDataProviderProps {
  children: ReactNode;
}

const PublicMpStepperDataProvider: React.FC<PublicMpStepperDataProviderProps> = ({
  children,
}) => {
  const [publicMpStepperData, setPublicMpStepperData] = useState<
    PublicMpStepperData | undefined
  >(undefined);

  const [publicMpFailedErrorType, setPublicMpFailedErrorType] = useState<
    PublicMpFailedErrorType
  >();

  const [publicMpToken, setPublicMpToken] = useState<string | undefined>();
  const [publicMpCode, setPublicMpCode] = useState<string | undefined>();
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

  const savePublicMpStepperData = (data: PublicMpStepperData) => {
    setPublicMpStepperData(data);
  };

  const handlePublicMpFailedErrorType = (
    errorType: PublicMpFailedErrorType
  ) => {
    setPublicMpFailedErrorType(errorType);
  };

  const handleSetPublicMpToken = (token: string) => setPublicMpToken(token);
  const handleSetPublicMpCode = (code: string) => setPublicMpCode(code);

  const handleSetRecaptchaValue = (value: string | null) =>
    setRecaptchaValue(value);

  const contextValue = useMemo(
    () => ({
      publicMpStepperData,
      savePublicMpStepperData,
      publicMpFailedErrorType,
      setPublicMpFailedErrorType: handlePublicMpFailedErrorType,
      publicMpToken,
      setPublicMpToken: handleSetPublicMpToken,
      publicMpCode,
      setPublicMpCode: handleSetPublicMpCode,
      setRecaptchaValue: handleSetRecaptchaValue,
      recaptchaValue,
    }),
    [
      publicMpStepperData,
      publicMpFailedErrorType,
      publicMpToken,
      publicMpCode,
      recaptchaValue,
    ]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export default PublicMpStepperDataProvider;
