import React, { useContext } from "react";
import { defineMessages } from "react-intl";
import {
  SecondaryActionCardType,
  SecondaryActionCardTypeForHeapEvent,
  SecondaryCard,
} from "@app/provider/testData/types";
import TeachingIcon from "@assets/svg/icons/teaching.svg";
import SalivaImage from "@assets/svg/icons/saliva.svg";
import BillImage from "@assets/svg/icons/bill.svg";
import DollarIcon from "@assets/svg/icons/dollar.svg";
import ContentAsideImage from "@assets/svg/contentAsideImage.svg";
import Svg from "@natera/material/lib/svg";
import { useHistory } from "react-router-dom";
import { routes } from "@app/routing";
import { ConfigContext, IntlContext, Language } from "@app/provider";
import { openNewWindow } from "@app/utils";
import { HEAP_EVENTS } from "@app/provider/types";
import { Path } from "history";

import "./secondaryActionCard.scss";

const messages = defineMessages({
  secondaryActionCardLearnMore: {
    id: "secondaryActionCardLearnMore",
    defaultMessage: "Learn More",
  },
});

export interface CardParam {
  icon: string;
  contentAside?: React.ReactNode;
  btn: {
    title: {
      id: string;
      defaultMessage: string;
    };
    action: () => void;
  };
}

interface CardParamProps {
  compassionateCareAction: () => void;
  card: SecondaryCard;
}

export const getCardTypeForHeap = (cardType: SecondaryActionCardType) => {
  switch (cardType) {
    case SecondaryActionCardType.COMPASSIONATE_CARE:
      return SecondaryActionCardTypeForHeapEvent.COMPCARE_INFO;
    case SecondaryActionCardType.WOMENS_HEALTH_PRICING_BILLING:
      return SecondaryActionCardTypeForHeapEvent.BILLING_FAQ_WH;
    case SecondaryActionCardType.ONCOLOGY_PRICING_BILLING:
      return SecondaryActionCardTypeForHeapEvent.BILLING_FAQ_ONC;
    case SecondaryActionCardType.EMPOWER:
      return SecondaryActionCardTypeForHeapEvent.EMPOWER_INFO;
    case SecondaryActionCardType.PANORAMA_HORIZON:
      return SecondaryActionCardTypeForHeapEvent.HORIZON_PANORAMA_INFO;
    case SecondaryActionCardType.HORIZON:
      return SecondaryActionCardTypeForHeapEvent.HORIZON_INFO;
    case SecondaryActionCardType.SALIVA:
      return SecondaryActionCardTypeForHeapEvent.SALIVA_COLLECTION_INFO;
    case SecondaryActionCardType.VISTARA:
      return SecondaryActionCardTypeForHeapEvent.VISTARA_INFO;
    case SecondaryActionCardType.GENETIC_TESTING:
      return SecondaryActionCardTypeForHeapEvent.MULTITEST_INFO_WH;
    case SecondaryActionCardType.PANORAMA:
      return SecondaryActionCardTypeForHeapEvent.PANORAMA_INFO;
    case SecondaryActionCardType.PROSPERA_HEART:
      return SecondaryActionCardTypeForHeapEvent.PROSPERA_INFO;
    case SecondaryActionCardType.PROSPERA_KIDNEY:
      return SecondaryActionCardTypeForHeapEvent.PROSPERA_INFO;
    case SecondaryActionCardType.PROSPERA_LIVER:
      return SecondaryActionCardTypeForHeapEvent.PROSPERA_INFO;
    case SecondaryActionCardType.PROSPERA_LUNG:
      return SecondaryActionCardTypeForHeapEvent.PROSPERA_INFO;
    case SecondaryActionCardType.PROSPERA_OTHER:
      return SecondaryActionCardTypeForHeapEvent.PROSPERA_INFO;
    case SecondaryActionCardType.PROSPERA_SPK:
      return SecondaryActionCardTypeForHeapEvent.PROSPERA_INFO;
    case SecondaryActionCardType.RENASIGHT_KIDNEY:
      return SecondaryActionCardTypeForHeapEvent.RENASIGHT_INFO;
    case SecondaryActionCardType.ALTERA:
      return SecondaryActionCardTypeForHeapEvent.ALTERA_INFO;
    case SecondaryActionCardType.SIGNATERA:
      return SecondaryActionCardTypeForHeapEvent.SIGNATERA_INFO;
    default:
      return "";
  }
};

const CardParams = ({
  compassionateCareAction,
  card,
}: CardParamProps): Record<SecondaryActionCardType, CardParam> => {
  const history = useHistory();
  const { config } = useContext(ConfigContext);
  const { currentLanguage } = useContext(IntlContext);

  const openNewWindowAndSendHeapEvent = (link: string) => {
    openNewWindow(link);
    heap.track(HEAP_EVENTS.upp_secondarycard_click, {
      card_type: getCardTypeForHeap(card.type),
      lims_clinic_id: card.limsClinicId,
    });
  };

  const historyPushAndSendHeapEvent = (location: Path) => {
    history.push(location);
    heap.track(HEAP_EVENTS.upp_secondarycard_click, {
      card_type: getCardTypeForHeap(card.type),
      lims_clinic_id: card.limsClinicId,
    });
  };

  const cardParams = React.useMemo(
    () => ({
      [SecondaryActionCardType.COMPASSIONATE_CARE]: {
        icon: DollarIcon,
        contentAside: (
          <Svg className="block-info-card__image bill" content={BillImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: compassionateCareAction,
        },
      },
      [SecondaryActionCardType.SALIVA]: {
        icon: TeachingIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={SalivaImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () =>
            historyPushAndSendHeapEvent(
              `${routes.displayVideo}/${SecondaryActionCardType.SALIVA}`
            ),
        },
      },
      [SecondaryActionCardType.RENASIGHT_KIDNEY]: {
        icon: TeachingIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={ContentAsideImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () =>
            openNewWindowAndSendHeapEvent(config.links.product.RENASIGHT),
        },
      },
      [SecondaryActionCardType.PROSPERA_KIDNEY]: {
        icon: TeachingIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={ContentAsideImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () =>
            openNewWindowAndSendHeapEvent(config.links.product.PROSPERA.KIDNEY),
        },
      },
      [SecondaryActionCardType.PROSPERA_SPK]: {
        icon: TeachingIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={ContentAsideImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () =>
            openNewWindowAndSendHeapEvent(config.links.product.PROSPERA.SPK),
        },
      },
      [SecondaryActionCardType.PROSPERA_HEART]: {
        icon: TeachingIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={ContentAsideImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () =>
            openNewWindowAndSendHeapEvent(config.links.product.PROSPERA.HEART),
        },
      },
      [SecondaryActionCardType.PROSPERA_LIVER]: {
        icon: TeachingIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={ContentAsideImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () =>
            openNewWindowAndSendHeapEvent(config.links.product.PROSPERA.LIVER),
        },
      },
      [SecondaryActionCardType.PROSPERA_LUNG]: {
        icon: TeachingIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={ContentAsideImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () =>
            openNewWindowAndSendHeapEvent(config.links.product.PROSPERA.LUNG),
        },
      },
      [SecondaryActionCardType.PROSPERA_OTHER]: {
        icon: TeachingIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={ContentAsideImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () =>
            openNewWindowAndSendHeapEvent(config.links.product.PROSPERA.OTHER),
        },
      },
      [SecondaryActionCardType.EMPOWER]: {
        icon: TeachingIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={ContentAsideImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () => {
            if (currentLanguage === Language.EN) {
              openNewWindowAndSendHeapEvent(config.links.product.EMPOWER.EN);
            } else {
              openNewWindowAndSendHeapEvent(config.links.product.EMPOWER.ES);
            }
          },
        },
      },
      [SecondaryActionCardType.HORIZON]: {
        icon: TeachingIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={ContentAsideImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () =>
            openNewWindowAndSendHeapEvent(config.links.product.HORIZON),
        },
      },
      [SecondaryActionCardType.PANORAMA]: {
        icon: TeachingIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={ContentAsideImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () =>
            openNewWindowAndSendHeapEvent(config.links.product.PANORAMA),
        },
      },
      [SecondaryActionCardType.VISTARA]: {
        icon: TeachingIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={ContentAsideImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () =>
            openNewWindowAndSendHeapEvent(config.links.product.VISTARA),
        },
      },
      [SecondaryActionCardType.GENETIC_TESTING]: {
        icon: TeachingIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={ContentAsideImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () =>
            openNewWindowAndSendHeapEvent(config.links.product.WOMENS_HEALTH),
        },
      },
      [SecondaryActionCardType.PANORAMA_HORIZON]: {
        icon: TeachingIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={ContentAsideImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () =>
            openNewWindowAndSendHeapEvent(
              config.links.product.PANORAMA_HORIZON
            ),
        },
      },
      [SecondaryActionCardType.ALTERA]: {
        icon: TeachingIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={ContentAsideImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () => {
            openNewWindowAndSendHeapEvent(config.links.product.ALTERA);
          },
        },
      },
      [SecondaryActionCardType.SIGNATERA]: {
        icon: TeachingIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={ContentAsideImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () => {
            openNewWindowAndSendHeapEvent(config.links.product.SIGNATERA);
          },
        },
      },
      [SecondaryActionCardType.WOMENS_HEALTH_PRICING_BILLING]: {
        icon: DollarIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={BillImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () =>
            openNewWindowAndSendHeapEvent(
              config.links.WOMENS_HEALTH_PRICING_BILLING
            ),
        },
      },
      [SecondaryActionCardType.ONCOLOGY_PRICING_BILLING]: {
        icon: DollarIcon,
        contentAside: (
          <Svg className="block-info-card__image" content={BillImage} />
        ),
        btn: {
          title: messages.secondaryActionCardLearnMore,
          action: () =>
            openNewWindowAndSendHeapEvent(
              config.links.ONCOLOGY_PRICING_BILLING
            ),
        },
      },
    }),
    [currentLanguage]
  );

  return cardParams;
};

export default CardParams;
