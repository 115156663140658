import { HeapDrawRequestFlow, TestDetailsRecord } from "@app/provider/types";
import { DrawRequestDataForHeapEventResponse } from "@app/provider/heapAnalyticData";
import { VerifiedSampleDrawResponse } from "@app/provider/drawRequest";
import { businessUnitMapper } from "@app/utils/businessUnitMapper";
import { UppUser } from "@app/service/user";

export interface GetParamsForHeapMPApptFailureEventData {
  verifiedSampleDrawData?: VerifiedSampleDrawResponse;
  testDetails?: TestDetailsRecord;
  drawRequestDataForHeapEventData?: DrawRequestDataForHeapEventResponse;
}

export interface ParamsForHeapMPApptFailureEventProps {
  business_unit?: string;
  test_name?: string;
  lims_clinic_id?: string | number;
  flow?: HeapDrawRequestFlow;
}

export type GetParamsForHeapMPApptFailureEventProps = (
  isPublic: boolean,
  profile: UppUser | null,
  props: GetParamsForHeapMPApptFailureEventData
) => ParamsForHeapMPApptFailureEventProps;

export const getParamsForHeapMPApptFailureEvent: GetParamsForHeapMPApptFailureEventProps = (
  isPublic,
  profile,
  { verifiedSampleDrawData, testDetails, drawRequestDataForHeapEventData }
) => {
  const getBusinessUnit = () => {
    if (isPublic) {
      return businessUnitMapper(verifiedSampleDrawData?.businessUnit);
    }
    if (testDetails) {
      return businessUnitMapper(testDetails.businessUnit);
    }
    return drawRequestDataForHeapEventData?.business_unit;
  };

  const test_name =
    verifiedSampleDrawData?.sampleInfo?.sample?.testName ??
    drawRequestDataForHeapEventData?.job_type ??
    testDetails?.testType;

  const getClinicLimsId = () => {
    if (isPublic) {
      return verifiedSampleDrawData?.sampleInfo?.sample?.lims_clinic_id ?? "";
    }
    if (testDetails) {
      return testDetails.clinic?.limsId;
    }
    return drawRequestDataForHeapEventData?.lims_clinic_id;
  };

  const getFlow = () => {
    if (isPublic) {
      return HeapDrawRequestFlow.public;
    }
    if (profile) {
      return HeapDrawRequestFlow.account;
    }
    return HeapDrawRequestFlow.guest;
  };

  return {
    business_unit: getBusinessUnit(),
    test_name,
    lims_clinic_id: getClinicLimsId(),
    flow: getFlow(),
  };
};
