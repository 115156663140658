import React from "react";
import { useLazyQuery } from "@apollo/client";
import TokenizedLinksService from "@app/service/tokenizedLinks";
import { useQuery } from "@app/utils";
import { useHistory } from "react-router-dom";
import { SecurityContext } from "@natera/platform/lib/provider/security";
import { routes } from "@app/routing";
import {
  PrimaryAction,
  TestCard,
  TestCardStatus,
  TestType,
} from "@app/provider/testData/types";
import {
  Language,
  TestCardUtilsContext,
  UserContext,
  ViewResultContext,
  OrderRenewalContext,
} from "@app/provider";
import { STAGE } from "@app/service/user";
import { TestDetailsRecord } from "@app/provider/types";
import TestCardService from "@app/service/testCard";
import { UrlVideoType } from "@app/pages/private/simpleOrder/aboutYourTest/aboutYourTest";

export enum TokenizedLinkAction {
  ACTIVATE_USER = "ACTIVATE_USER",
  RESET_PASSWORD = "RESET_PASSWORD",
  UNLOCK_USER = "UNLOCK_USER",
  UPDATE_EMAIL = "UPDATE_EMAIL",
  OPEN_NEW_ORDER = "OPEN_NEW_ORDER",
  OPEN_TEST_RESULT = "OPEN_TEST_RESULT",
  SCHEDULE_DRAW = "SCHEDULE_DRAW",
  SIMPLE_ORDER_COMPLETION = "SIMPLE_ORDER_COMPLETION",
  OPEN_RENEWAL_PAGE = "OPEN_RENEWAL_PAGE",
}

export enum LinkSource {
  SMS = "sms",
  EMAIL = "email",
}

export type TokenizedLinkData =
  | ActivateUserData
  | TestData
  | ResetPasswordData
  | UpdateEmailData;

export type ActivateUserData = {
  email: string;
};

export type TestData = {
  orderUid: string;
  testUid: string;
  testType: TestType;
};

export type ResetPasswordData = {
  email: string;
  unlock: boolean | undefined;
};

export type UpdateEmailData = {
  email: string;
  nextEmail: string;
};

export type TokenPayload =
  | ActivateUser
  | ResetPassword
  | UpdateEmail
  | OpenOrder
  | OpenTestResult
  | ScheduleDraw
  | SimpleOrderCompletion
  | OpenRenewalPage;

export interface LinkSourceData {
  source: LinkSource;
  language?: Language;
}

export interface LinkUserData {
  email: string;
}

export type ActivateUser = {
  action: TokenizedLinkAction.ACTIVATE_USER;
  data: ActivateUserData;
  sourceData: LinkSourceData;
};

export type ResetPassword = {
  action: TokenizedLinkAction.RESET_PASSWORD;
  data: ResetPasswordData;
  sourceData: LinkSourceData;
};

export type UnlockUser = {
  action: TokenizedLinkAction.UNLOCK_USER;
  data: ResetPasswordData;
  sourceData: LinkSourceData;
};

export type UpdateEmail = {
  action: TokenizedLinkAction.UPDATE_EMAIL;
  data: UpdateEmailData;
  sourceData: LinkSourceData;
};

export type OpenOrder = {
  action: TokenizedLinkAction.OPEN_NEW_ORDER;
  data: TestData;
  sourceData: LinkSourceData;
};

export type OpenTestResult = {
  action: TokenizedLinkAction.OPEN_TEST_RESULT;
  data: TestData;
  sourceData: LinkSourceData;
};

export type OpenRenewalPage = {
  action: TokenizedLinkAction.OPEN_RENEWAL_PAGE;
  data: TestData;
  sourceData: LinkSourceData;
};

export type ScheduleDraw = {
  action: TokenizedLinkAction.SCHEDULE_DRAW;
  data: TestData;
  sourceData: LinkSourceData;
};

export type SimpleOrderCompletion = {
  action: TokenizedLinkAction.SIMPLE_ORDER_COMPLETION;
  data: TestData;
  sourceData: LinkSourceData;
};

type TokenizedLinksController = {
  checkUserExistByToken: (token: string) => Promise<boolean>;
  isLoading: boolean;
  getTokenSource: (token: string) => Promise<LinkSourceData | undefined>;
  getUserEmailByToken: (token: string) => Promise<string | undefined>;
};

type UseTokenizedLink = () => TokenizedLinksController;

const useTokenizedLink: UseTokenizedLink = () => {
  const notRedirectedLinks = [
    routes.changePassword,
    routes.setPassword,
    routes.updateEmail,
    routes.activation,
    routes.scheduleBloodDraw,
    routes.drawRequest,
  ];
  const query = useQuery();
  const history = useHistory();
  const token = query.get("token");
  const { isAuthorized } = React.useContext(SecurityContext);
  const { getTestCardData } = React.useContext(TestCardUtilsContext);
  const { uppUser } = React.useContext(UserContext);
  const { openResultPage, openTestResultDetailsPage } = React.useContext(
    ViewResultContext
  );

  const { openRenewalDialog } = React.useContext(OrderRenewalContext);

  const [isLoading, setLoading] = React.useState<boolean>(false);

  const isAuth = isAuthorized();
  const [isUserExist] = useLazyQuery<{
    checkUserExistByToken: { isExist: boolean };
  }>(TokenizedLinksService.isUserExist());

  const [getResourceFromToken] = useLazyQuery<{
    getResourceFromToken: TokenPayload;
  }>(TokenizedLinksService.getResourceFromToken());

  const [getTokenSource] = useLazyQuery<{
    getTokenSource: LinkSourceData;
  }>(TokenizedLinksService.getTokenSource());

  const [getUserEmailByToken] = useLazyQuery<{
    getUserEmailByToken: LinkUserData;
  }>(TokenizedLinksService.getUserEmailByToken());

  const [getTestCardDetails] = useLazyQuery<{
    getTestCardDetails: TestDetailsRecord;
  }>(TestCardService.getTestCardDetails(), {
    fetchPolicy: "network-only",
  });

  const checkUserExistByToken = async (tokenFromLink: string) => {
    const response = await isUserExist({ variables: { token: tokenFromLink } });
    return Boolean(response.data?.checkUserExistByToken?.isExist);
  };

  const handleGetTestDetails = async (orderUid: string, testUid: string) => {
    const response = await getTestCardDetails({
      variables: { orderUid, testUid },
    });

    const testDetailsData = response.data?.getTestCardDetails;

    return getTestCardData({ ...testDetailsData } as TestCard);
  };

  const openResult = async (payload: OpenTestResult) => {
    const test = await handleGetTestDetails(
      payload.data.orderUid,
      payload.data.testUid
    );
    setLoading(false);
    if (test.status === TestCardStatus.RESULT_READY) {
      openResultPage({ ...test });
    } else {
      openTestResultDetailsPage({
        ...test,
      });
    }
  };

  const openScheduleDraw = async (payload: ScheduleDraw) => {
    const test = await handleGetTestDetails(
      payload.data.orderUid,
      payload.data.testUid
    );

    if (test.primaryAction === PrimaryAction.SCHEDULE_A_DRAW) {
      history.replace(
        routes.sampleDrawPage(payload.data.orderUid, payload.data.testUid),
        {
          fromTokenizedLink: true,
        }
      );
    } else {
      history.replace(routes.home);
    }
  };

  const openSimpleOrderCompletionFlow = async (
    payload: SimpleOrderCompletion
  ) => {
    const test = await handleGetTestDetails(
      payload.data.orderUid,
      payload.data.testUid
    );
    if (test.status === TestCardStatus.TEST_ORDERED) {
      const urlVideoType = test.isComboOrder
        ? UrlVideoType.COMBO_ORDER
        : test.testType;
      history.replace(
        routes.aboutTestPage(payload.data.orderUid, urlVideoType)
      );
    } else {
      history.replace(routes.home);
    }
  };

  const getResource = async (tokenFromLink: string) => {
    setLoading(true);
    const response = await getResourceFromToken({
      variables: { token: tokenFromLink },
    });
    const payload = response.data?.getResourceFromToken;
    if (!payload) {
      history.replace(routes.home);
      return;
    }

    switch (payload.action) {
      case TokenizedLinkAction.OPEN_TEST_RESULT:
        openResult(payload);
        break;
      case TokenizedLinkAction.SCHEDULE_DRAW:
        setLoading(false);
        openScheduleDraw(payload);
        break;
      case TokenizedLinkAction.SIMPLE_ORDER_COMPLETION:
        setLoading(false);
        openSimpleOrderCompletionFlow(payload);
        break;
      case TokenizedLinkAction.OPEN_RENEWAL_PAGE:
        setLoading(false);
        openRenewalDialog();
        break;
      case TokenizedLinkAction.OPEN_NEW_ORDER:
      default:
        setLoading(false);
        history.replace(routes.home);
    }
  };

  const getTokenSourceHandler = async (token: string) => {
    const response = await getTokenSource({
      variables: { token },
    });
    const source = response.data?.getTokenSource;

    return source;
  };

  const getUserEmailByTokenHandler = async (token: string) => {
    const response = await getUserEmailByToken({
      variables: { token },
    });
    const email = response.data?.getUserEmailByToken.email;
    return email;
  };

  const handle = async () => {
    if (!token) return;

    const isRedirectedNeeded = !notRedirectedLinks.includes(
      history.location.pathname
    );
    if (!isRedirectedNeeded) return;

    if (!isAuth) {
      const userExist = await checkUserExistByToken(token);
      const correctRoute = userExist ? routes.signIn : routes.signUp;
      history.replace(`${correctRoute}/?token=${token}`);
    } else {
      if (uppUser?.stage === STAGE.FILLED) {
        getResource(token);
      }
    }
  };

  React.useEffect(() => {
    handle();
  }, [isAuth, token, uppUser?.stage]);

  return {
    checkUserExistByToken,
    isLoading,
    getTokenSource: getTokenSourceHandler,
    getUserEmailByToken: getUserEmailByTokenHandler,
  };
};

export default useTokenizedLink;
