import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useIntl, defineMessages } from "react-intl";
import { useHistory, Link } from "react-router-dom";

import { useErrorController } from "@natera/platform/lib/hooks";
import { useDialog } from "@natera/platform/lib/hooks";
import { routes } from "@app/routing";
import {
  ErrorProvider,
  NotificationContext,
  SignUpContext,
  UserContext,
  ServiceContext,
  Language,
  UppAuthContext,
  IntlContext,
} from "@app/provider";
import {
  SignInWithAuthenticationProviders,
  AcknowledgmentsDialog,
  LinkHeap,
} from "@app/components";
import { ResultCodes } from "@app/service/resultCodes";
import SpinnerView from "@app/components/spinnerView";
import acknowledgements from "@etc/acknowledgements.json";
import SignUpByPassswordForm from "./signUpByPasswordForm";
import { isValidLanguageValue, useQuery } from "@app/utils";
import { useTokenizedLinks } from "@app/hooks";
import { LinkSource } from "@app/hooks/useTokenizedLinks";
import SendActivationEmailDialog from "@app/pages/public/signUp/sendActivationEmailDialog";
import "./signUp.scss";
import { IntlShape } from "react-intl/src/types";
import { AddNotification } from "@app/provider/notification";
import { HEAP_EVENTS, HeapEventLocation } from "@app/provider/types";
import { ACKNOWLEDGMENT_FLOW } from "@app/components/acknowledgmentsDialog/acknowledgmentsDialog";

const messages = defineMessages({
  signUpNewToNatera: {
    id: "signUpNewToNatera",
    defaultMessage: "New to Natera?",
  },
  signUpTitle: {
    id: "signUpTitle",
    defaultMessage: "Sign Up",
  },
  signUpTermsOfUse: {
    id: "signUpTermsOfUse",
    defaultMessage: "Terms of Use",
  },
  signUpPrivacyPolicy: {
    id: "signUpPrivacyPolicy",
    defaultMessage: "Privacy Policy",
  },
  signUpAuthenticationTypesOr: {
    id: "signUpAuthenticationTypesOr",
    defaultMessage: "Or",
  },
  signUpAlreadyHaveAnAccount: {
    id: "signUpAlreadyHaveAnAccount",
    defaultMessage: "Already have an account?",
  },
  signUpSignInTitle: {
    id: "signUpSignInTitle",
    defaultMessage: "Log In",
  },
  signUpAcknowledgements: {
    id: "signUpAcknowledgements",
    defaultMessage:
      "By continuing, you are accepting our {termsOfUse} and {privacyPolicy}",
  },
  signUpUnrecognizedError: {
    id: "signUpUnrecognizedError",
    defaultMessage: "We're sorry. Something went wrong.",
  },
  signUpUserExistedNotification: {
    id: "signUpUserExistedNotification",
    defaultMessage: "This email address is already in use.",
  },
  signUpUserNetworkError: {
    id: "signUpUserNetworkError",
    defaultMessage:
      "We're sorry. An error occurred when setting up your profile.",
  },
  signUpSupportContact: {
    id: "signUpSupportContact",
    defaultMessage: "Contact support",
  },
  signUpForbiddenDomainTitle: {
    id: "signUpForbiddenDomainTitle",
    defaultMessage: "You are trying to use a Natera email address to sign up.",
  },
  signUpForbiddenDomainMessage: {
    id: "signUpForbiddenDomainMessage",
    defaultMessage:
      'Please either select the "Continue with Google" option, or sign up with email and password using a non-Natera email address.',
  },
});

interface SignUpProps {
  email: string;
  password: string;
  token: string | null;
  language: Language;
}

export const checkAccess = (
  email: string,
  intl: IntlShape,
  addNotification: AddNotification
) => {
  const forbiddenDomain = "@natera.com";

  if (
    email.includes(forbiddenDomain) &&
    process.env.NODE_ENV !== "development"
  ) {
    addNotification({
      title: intl.formatMessage(messages.signUpForbiddenDomainTitle),
      message: intl.formatMessage(messages.signUpForbiddenDomainMessage),
      type: "warning",
    });
    return false;
  }
  return true;
};

const SignUp: FC = () => {
  const intl = useIntl();
  const query = useQuery();
  const history = useHistory();
  const token = query.get("token");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tokenSource, setTokenSource] = useState<LinkSource | undefined>();
  const { setSignUpEmail } = useContext(UppAuthContext);
  const { changeLanguage, currentLanguage } = useContext(IntlContext);
  const [initialEmail, setInitialEmail] = useState<string | undefined>();

  const { getTokenSource, getUserEmailByToken } = useTokenizedLinks();

  const getLinkTokenSource = React.useCallback(async () => {
    if (token) {
      const data = await getTokenSource(token);
      if (data?.source === LinkSource.EMAIL) {
        const userEmail = await getUserEmailByToken(token);
        setInitialEmail(userEmail);
      }
      const lang = data?.language ?? null;
      if (lang && currentLanguage !== lang && isValidLanguageValue(lang)) {
        changeLanguage(lang);
      }

      setTokenSource(data?.source);
    } else {
      setTokenSource(undefined);
    }
  }, [token]);

  React.useEffect(() => {
    getLinkTokenSource();
  }, [getLinkTokenSource]);

  const {
    signUpByPasswordError,
    signUpByPassword,
    signUpByPasswordNetworkError,
  } = useContext(SignUpContext);
  const { isLoading: userIsLoading, loadProfile } = useContext(UserContext);

  const { sessionService } = useContext(ServiceContext);
  const { addNotification } = useContext(NotificationContext);

  const errorController = useErrorController();
  const acknowdledgementsDialog = useDialog(AcknowledgmentsDialog);
  const sendActivationEmailDialog = useDialog(SendActivationEmailDialog);

  useEffect(() => {
    if (signUpByPasswordNetworkError) {
      sendActivationEmailDialog.close();
      addNotification({
        type: "error",
        message: intl.formatMessage(messages.signUpUserNetworkError),
        actions: (
          <LinkHeap
            target="_blank"
            key="privacyPolicy"
            rel="noreferrer"
            to={{ pathname: acknowledgements.links.contactUs }}
            heapEventName={HEAP_EVENTS.upp_click_contactnatera}
            heapEventProps={{
              location: HeapEventLocation.update_info_error,
            }}
          >
            {intl.formatMessage(messages.signUpSupportContact)}
          </LinkHeap>
        ),
      });
    }

    if (signUpByPasswordError) {
      switch (signUpByPasswordError) {
        case ResultCodes.USER_ALREADY_CREATED_ERROR: {
          addNotification({
            title: intl.formatMessage(messages.signUpUserExistedNotification),
            message: (
              <Link to={routes.signIn}>
                {intl.formatMessage(messages.signUpSignInTitle)}
              </Link>
            ),
            type: "error",
          });

          break;
        }

        default: {
          addNotification({
            type: "error",
            message: intl.formatMessage(messages.signUpUnrecognizedError),
          });
        }
      }
    }
  }, [signUpByPasswordError, signUpByPasswordNetworkError]);

  const handleSendActivationEmail = (props: SignUpProps) => async () => {
    try {
      setSignUpEmail(props.email);

      const signUpByPasswordData = await signUpByPassword(props);
      setIsLoading(true);
      acknowdledgementsDialog.close();
      sendActivationEmailDialog.close();

      if (signUpByPasswordData) {
        if (signUpByPasswordData?.data?.signUpByPassword?.isTokenValid) {
          if (await sessionService.getToken()) {
            sessionService.clearToken();
          }

          await sessionService.login(props);
          await loadProfile();
          setIsLoading(false);
        } else {
          setIsLoading(false);

          history.replace(routes.activation, props);
        }
      }
    } catch (error) {
      setIsLoading(false);
      acknowdledgementsDialog.close();
      sendActivationEmailDialog.close();
      console.log(error);
    }
  };

  const openAcknowdledgementsDialog = async (props: SignUpProps) => {
    const { email } = props;
    checkAccess(email, intl, addNotification) &&
      acknowdledgementsDialog.open({
        userEmail: email,
        onAgreeAcknowledgements: () => {
          if (token) {
            handleSendActivationEmail({
              ...props,
              token,
            })();
          } else {
            acknowdledgementsDialog.close();
            openSendActivationEmailDialog(props);
          }
        },
        onCloseDialog: acknowdledgementsDialog.close,
        flow: ACKNOWLEDGMENT_FLOW.ACCOUNT,
      });
  };

  const openSendActivationEmailDialog = async (props: SignUpProps) => {
    sendActivationEmailDialog.open({
      onSend: handleSendActivationEmail({
        ...props,
        token,
      }),
      onClose: () => {
        sendActivationEmailDialog.close();
        openAcknowdledgementsDialog(props);
      },
    });
  };

  const title: ReactElement = (
    <section className="title__container">
      <span>{intl.formatMessage(messages.signUpNewToNatera)}</span>
      <h1>{intl.formatMessage(messages.signUpTitle)}</h1>
    </section>
  );

  const conditions: ReactElement = (
    <section className="conditions__container">
      <span className="acknowledgements__text">
        {intl.formatMessage(messages.signUpAcknowledgements, {
          termsOfUse: (
            <a
              href={acknowledgements.links.termsOfUse}
              key="termsOfUse"
              target="_blank"
              rel="noreferrer"
            >
              {intl.formatMessage(messages.signUpTermsOfUse)}
            </a>
          ),
          privacyPolicy: (
            <a
              href={acknowledgements.links.privacyPolicy}
              key="privacyPolicy"
              target="_blank"
              rel="noreferrer"
            >
              {intl.formatMessage(messages.signUpPrivacyPolicy)}
            </a>
          ),
        })}
      </span>
    </section>
  );

  const authenticationProviders: ReactElement = (
    <SignInWithAuthenticationProviders
      invite={token}
      tokenSource={tokenSource}
    />
  );

  const separator: ReactElement = (
    <div className="separator__container">
      <hr />
      <span>{intl.formatMessage(messages.signUpAuthenticationTypesOr)}</span>
      <hr />
    </div>
  );

  const signUpByPasswordForm: ReactElement = (
    <section className="form__container">
      <ErrorProvider controller={errorController}>
        <SignUpByPassswordForm
          setValidationError={errorController.setValidationError}
          clearValidationError={errorController.clearValidationError}
          clearErrors={errorController.clearErrors}
          onSubmit={openAcknowdledgementsDialog}
          initialEmail={initialEmail}
        />
      </ErrorProvider>
    </section>
  );

  const signIn: ReactElement = (
    <section className="to-sign-in__container">
      <span>{intl.formatMessage(messages.signUpAlreadyHaveAnAccount)} </span>
      <Link to={routes.signIn}>
        {intl.formatMessage(messages.signUpSignInTitle)}
      </Link>
    </section>
  );

  const isLoadingContext = userIsLoading || isLoading;

  return (
    <article className="sign-up__container">
      <SpinnerView isLoading={isLoadingContext} />
      {acknowdledgementsDialog.getDialog()}
      {sendActivationEmailDialog.getDialog()}
      {title}
      {authenticationProviders}
      {conditions}
      {separator}
      {signUpByPasswordForm}
      {signIn}
    </article>
  );
};

export default SignUp;
