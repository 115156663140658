import React from "react";
import { useLazyQuery } from "@apollo/client";
import BloodDrawService from "@app/service/bloodDraw";

export enum DAY_OF_WEEK {
  SUN = "Sun",
  MON = "Mon",
  TUE = "Tue",
  WED = "Wed",
  THU = "Thu",
  FRI = "Fri",
  SAT = "Sat",
}

export interface SiteHour {
  id: number;
  day_of_week: DAY_OF_WEEK;
  close?: string;
  open?: string;
  phlebotomy_site_id: number;
}

export interface BloodDrawSite {
  id: number;
  clinic_id: number;
  name: string;
  full_address: string;
  distance: number;
  phone: string;
  zip: string;
  site_hours: SiteHour[];
  comments?: string;
  appointment_scheduling_url?: string;
}

type GetBloodDrawSites = (props: {
  location: string;
  distance?: number;
}) => Promise<BloodDrawSite[] | undefined>;

export interface BloodDrawController {
  isLoading: boolean;
  bloodDrawSites?: BloodDrawSite[];
  getBloodDrawSites: GetBloodDrawSites;
  zipCode?: string;
  setZipCode: (zipCode: string) => void;
}

export const Context = React.createContext<BloodDrawController>({
  isLoading: false,
  bloodDrawSites: undefined,
  getBloodDrawSites: async () => undefined,
  zipCode: undefined,
  setZipCode: () => undefined,
});

Context.displayName = "BloodDrawContext";

const BloodDrawProvider: React.FC = ({ children }) => {
  const [bloodDrawSites, setBloodDrawSites] = React.useState<
    BloodDrawSite[] | undefined
  >();
  const [zipCode, setZipCode] = React.useState<string | undefined>();

  const [
    getBloodDrawSites,
    { loading: getBloodDrawSitesIsLoading },
  ] = useLazyQuery<{
    getBloodDrawSites: BloodDrawSite[];
  }>(BloodDrawService.getBloodDrawSites(), { fetchPolicy: "network-only" });

  const handleGetBloodDrawSites = async (props: {
    location: string;
    distance?: number;
  }) => {
    const response = await getBloodDrawSites({
      variables: { bloodDrawSitesData: props },
    });

    setBloodDrawSites(response.data?.getBloodDrawSites);

    return response.data?.getBloodDrawSites;
  };

  const handleSetZipCode = (zipCode: string) => {
    setZipCode(zipCode);
  };

  return (
    <Context.Provider
      value={{
        isLoading: getBloodDrawSitesIsLoading,
        bloodDrawSites,
        getBloodDrawSites: handleGetBloodDrawSites,
        zipCode,
        setZipCode: handleSetZipCode,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default BloodDrawProvider;
