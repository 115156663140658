import React, { FC, useCallback } from "react";
import { DrawRequestScreens } from "../drawRequest";
import { Step, Stepper } from "@natera/stepper";
import {
  ConfirmationScreen,
  ContactInfoScreen,
  DobScreen,
  NameScreen,
  ZipCodeScreen,
} from "./stepScreens";
import { Notification } from "@app/components";
import { messages } from "./index";
import { useIntl } from "react-intl";
import { useTimers, useEventListeners } from "@app/hooks";

import "./personalInformation.scss";

const TIMEOUT = 1000 * 60 * 30;

export enum PersonalInfoSteps {
  NAME_SCREEN = "NAME_SCREEN",
  DOB_SCREEN = "DOB_SCREEN",
  ZIP_CODE_SCREEN = "ZIP_CODE_SCREEN",
  CONTACT_INFO_SCREEN = "CONTACT_INFO_SCREEN",
  CONFIRMATION_SCREEN = "CONFIRMATION_SCREEN",
}

export const PersonalInfoNotification: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <Notification
        className={"public-mp-personal-information__content-note"}
        type="info"
      >
        {intl.formatMessage(
          messages.publicMpPersonalInformationFormNotificationText
        )}
      </Notification>
    </>
  );
};

type PersonalInformationProps = {
  setScheduleScreen: React.Dispatch<React.SetStateAction<DrawRequestScreens>>;
};

const PersonalInformation: FC<PersonalInformationProps> = ({
  setScheduleScreen,
}) => {
  const refreshPageOnTimeout = () => {
    window.location.reload();
  };

  const { setTimer, clearTimer } = useTimers(refreshPageOnTimeout, TIMEOUT);

  const resetTimers = useCallback(() => {
    clearTimer();
    setTimer();
  }, []);

  useEventListeners(
    resetTimers,
    ["mousemove", "mousedown", "keydown", "touchstart"],
    window,
    { passive: true }
  );

  return (
    <>
      <div className="public-mp-personal-information">
        <Stepper
          initialStep={PersonalInfoSteps.NAME_SCREEN}
          progressStepper
          className="public-mp-personal-information-stepper stepper"
        >
          <Step stepId={PersonalInfoSteps.NAME_SCREEN}>
            <NameScreen setScheduleScreen={setScheduleScreen} />
          </Step>

          <Step stepId={PersonalInfoSteps.DOB_SCREEN}>
            <DobScreen />
          </Step>
          <Step stepId={PersonalInfoSteps.ZIP_CODE_SCREEN}>
            <ZipCodeScreen />
          </Step>
          <Step stepId={PersonalInfoSteps.CONTACT_INFO_SCREEN}>
            <ContactInfoScreen />
          </Step>
          <Step stepId={PersonalInfoSteps.CONFIRMATION_SCREEN}>
            <ConfirmationScreen setScheduleScreen={setScheduleScreen} />
          </Step>
        </Stepper>
      </div>
    </>
  );
};

export default PersonalInformation;
