import { messages as setupProfileFormMessages } from "@app/components/forms/setupProfileForm";
import { messages as setupProfileReviewInfoMessages } from "@app/components/setupProfileReviewInfo";

import { defineMessages } from "react-intl";

const publicMpFormMessages = defineMessages({
  publicMpPersonalInfoPageBackButton: {
    id: "publicMpPersonalInfoPageBackButton",
    defaultMessage: "Back",
  },
  publicMpContactInfoFormEmailInvalid: {
    id: "publicMpContactInfoFormEmailInvalid",
    defaultMessage: "Please enter a valid email address. (username@domain)",
  },
  publicMpContactInfoFormEmailField: {
    id: "publicMpContactInfoFormEmailField",
    defaultMessage: "Email Address",
  },
  publicMpContactInfoFormEmailFormat: {
    id: "publicMpContactInfoFormEmailFormat",
    defaultMessage: "email format (username@domain)",
  },
  publicMpContactInfoFormEmailPlaceholder: {
    id: "publicMpContactInfoFormEmailPlaceholder",
    defaultMessage: "Enter Your Email Address",
  },
  publicMpContactInfoFormEmailUserExists: {
    id: "publicMpContactInfoFormEmailUserExists",
    defaultMessage: "This email address is already in use.",
  },
});

export const messages = {
  ...setupProfileFormMessages,
  ...setupProfileReviewInfoMessages,
  ...publicMpFormMessages,
};
