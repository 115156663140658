import React, { FC, useContext } from "react";

import { defineMessages, useIntl } from "react-intl";
import Button from "@natera/material/lib/button";
import { DrawRequestScreens } from "../drawRequest";

import "./kitReqFormConfirmation.scss";
import { BloodDrawContext } from "@app/provider";
import { HEAP_EVENTS } from "@app/provider/types";

const messages = defineMessages({
  kitReqFormConfirmationText1: {
    id: "kitReqFormConfirmationText1",
    defaultMessage:
      "You'll need your Natera test kit and signed order form. If you don't have them, you can still proceed with scheduling a mobile draw.",
  },
  kitReqFormConfirmationText2: {
    id: "kitReqFormConfirmationText2",
    defaultMessage: "Do you have your Natera test kit and signed order form?",
  },
  kitReqFormConfirmationButtonYes: {
    id: "kitReqFormConfirmationButtonYes",
    defaultMessage: "Yes, I have both",
  },
  kitReqFormConfirmationButtonNo: {
    id: "kitReqFormConfirmationButtonNo",
    defaultMessage: "No, schedule a mobile draw",
  },
});

export type KitReqFormConfirmationProps = {
  setScheduleScreen: React.Dispatch<React.SetStateAction<DrawRequestScreens>>;
  onOpenAcknowledgementsDialog: () => void;
};

const KitReqFormConfirmation: FC<KitReqFormConfirmationProps> = ({
  setScheduleScreen,
  onOpenAcknowledgementsDialog,
}) => {
  const intl = useIntl();

  const { getBloodDrawSites, zipCode } = useContext(BloodDrawContext);

  const handleYes = () => {
    getBloodDrawSites({ location: zipCode! });
    heap.track(HEAP_EVENTS.upp_public_url_zip_search, {
      zip_code: zipCode,
    });
    setScheduleScreen(DrawRequestScreens.SAMPLE_DRAW_PREFERENCE_SCREEN);
  };

  return (
    <div className="kit-req-form-confirmation">
      <div className="kit-req-form-confirmation__content">
        <div className="kit-req-form-confirmation__content-description">
          <p>{intl.formatMessage(messages.kitReqFormConfirmationText1)}</p>
          <p>{intl.formatMessage(messages.kitReqFormConfirmationText2)}</p>
        </div>

        <div className="buttons">
          <Button raised onClick={handleYes}>
            {intl.formatMessage(messages.kitReqFormConfirmationButtonYes)}
          </Button>
          <Button outlined onClick={onOpenAcknowledgementsDialog}>
            {intl.formatMessage(messages.kitReqFormConfirmationButtonNo)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default KitReqFormConfirmation;
