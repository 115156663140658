import { AddressForm } from "@app/components/forms";
import { Address } from "@app/provider";
import { Button } from "@natera/platform/lib/components/form";
import React from "react";
import { defineMessages, useIntl } from "react-intl";

export const messages = defineMessages({
  sampleDrawKitAddAddressNextButtonText: {
    id: "sampleDrawKitAddAddressNextButtonText",
    defaultMessage: "Save",
  },
  sampleDrawKitAddAddressBackButtonText: {
    id: "sampleDrawKitAddAddressBackButtonText",
    defaultMessage: "Back",
  },
});

type Props = {
  onSubmit: (address: Partial<Address>) => void;
  onBack?: () => void;
};

const SampleDrawKitAddAddress: React.FC<Props> = ({ onSubmit, onBack }) => {
  const intl = useIntl();

  return (
    <AddressForm
      onSubmit={onSubmit}
      actions={
        <>
          <Button type="submit" data-testid="submit" raised>
            {intl.formatMessage(messages.sampleDrawKitAddAddressNextButtonText)}
          </Button>
          {onBack && (
            <Button type="reset" data-testid="cancel" onClick={onBack}>
              {intl.formatMessage(
                messages.sampleDrawKitAddAddressBackButtonText
              )}
            </Button>
          )}
        </>
      }
    />
  );
};

export default SampleDrawKitAddAddress;
