import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { InfoCard, InfoCardField } from "@app/components";
import { SetupProfileData } from "@app/provider/profile/types";
import { Button, Form } from "@natera/platform/lib/components/form";
import { convertPhoneNumberToDisplayValue } from "@app/utils/convertPhoneNumber";
import "./setupProfileReviewInfo.scss";

export const messages = defineMessages({
  setupProfileReviewInfoFirstName: {
    id: "setupProfileReviewInfoFirstName",
    defaultMessage: "First Name",
  },
  setupProfileReviewInfoMiddleName: {
    id: "setupProfileReviewInfoMiddleName",
    defaultMessage: "Middle Name",
  },
  setupProfileReviewInfoLastName: {
    id: "setupProfileReviewInfoLastName",
    defaultMessage: "Last Name",
  },
  setupProfileReviewInfoAlternateLastName: {
    id: "setupProfileReviewInfoAlternateLastName",
    defaultMessage: "Alternate Last Name (Optional)",
  },
  setupProfileReviewInfoDateOfBirth: {
    id: "setupProfileReviewInfoDateOfBirth",
    defaultMessage: "Date of Birth",
  },
  setupProfileReviewInfoMobileNumber: {
    id: "setupProfileReviewInfoMobileNumber",
    defaultMessage: "Mobile Number",
  },
  setupProfileReviewInfoZipCode: {
    id: "setupProfileReviewInfoZipCode",
    defaultMessage: "Zip Code",
  },
  setupProfileReviewInfoNotApplicable: {
    id: "setupProfileReviewInfoNotApplicable",
    defaultMessage: "Not Applicable",
  },
  setupProfileReviewInfoEditBtn: {
    id: "setupProfileReviewInfoEditBtn",
    defaultMessage: "Edit",
  },
  setupProfileReviewInfoConfirmBtn: {
    id: "setupProfileReviewInfoConfirmBtn",
    defaultMessage: "Confirm",
  },
});

interface SetupProfileReviewInfoProps {
  profileData: SetupProfileData;
  onEdit: () => void;
  onSubmit: () => void;
  isLoading?: boolean;
}

const SetupProfileReviewInfo: FC<SetupProfileReviewInfoProps> = ({
  profileData,
  onEdit,
  onSubmit,
  isLoading,
}) => {
  const intl = useIntl();

  const displayDateOfBirth = () => {
    const dOB = profileData.dateOfBirth;
    return dOB
      ? new Date(dOB).toLocaleDateString("en-US", {
          timeZone: "UTC",
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      : "";
  };

  const displayZipCode = () =>
    profileData.addresses?.length ? profileData.addresses[0].zipCode : "";

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Form className="setup-profile-review-info" onSubmit={handleSubmit}>
      <InfoCard>
        <InfoCardField
          label={intl.formatMessage(messages.setupProfileReviewInfoFirstName)}
          value={profileData.firstName}
        />
        <InfoCardField
          label={intl.formatMessage(messages.setupProfileReviewInfoMiddleName)}
          value={
            profileData.middleName ||
            intl.formatMessage(messages.setupProfileReviewInfoNotApplicable)
          }
        />
        <InfoCardField
          label={intl.formatMessage(messages.setupProfileReviewInfoLastName)}
          value={profileData.lastName}
        />
        <InfoCardField
          label={intl.formatMessage(
            messages.setupProfileReviewInfoAlternateLastName
          )}
          value={
            profileData.alternativeLastName ||
            intl.formatMessage(messages.setupProfileReviewInfoNotApplicable)
          }
        />
        <InfoCardField
          label={intl.formatMessage(messages.setupProfileReviewInfoDateOfBirth)}
          value={displayDateOfBirth()}
        />
        <InfoCardField
          label={intl.formatMessage(
            messages.setupProfileReviewInfoMobileNumber
          )}
          value={convertPhoneNumberToDisplayValue(profileData.phone)}
        />
        <InfoCardField
          label={intl.formatMessage(messages.setupProfileReviewInfoZipCode)}
          value={displayZipCode()}
        />
      </InfoCard>
      <Button onClick={onEdit} outlined disabled={isLoading}>
        {intl.formatMessage(messages.setupProfileReviewInfoEditBtn)}
      </Button>
      <Button type="submit" raised loading={isLoading}>
        {intl.formatMessage(messages.setupProfileReviewInfoConfirmBtn)}
      </Button>
    </Form>
  );
};

export default SetupProfileReviewInfo;
