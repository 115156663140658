import { HeapEventLocation } from "../types";

export enum ChannelType {
  SMS = "SMS",
  EMAIL = "EMAIL",
}

export enum ChannelValue {
  OPT_IN = "OPT_IN",
  OPT_OUT = "OPT_OUT",
  UNKNOWN = "UNKNOWN",
}

export class Language {
  code: string;
}

export interface PersonalInformation {
  firstName: string;
  middleName?: string;
  lastName: string;
  alternativeLastName?: string;
}

export interface Address {
  uid: string;
  countryCode: string;
  city: string;
  state: string;
  street1: string | null;
  street2: string | null;
  zipCode: string;
}

export interface AddressMatchInput {
  zipCode: string;
}

export interface Communication {
  channelType: ChannelType;
  value: ChannelValue;
}

export interface CommunicationPayload {
  heapEventLocation?: HeapEventLocation;
  token?: string;
}

export interface UpdateResponseData {
  success: boolean;
}
export interface PatientEthnicityDTO {
  uid: string;
  ethnicities: PatientEthnicityInput;
}

export interface Ethnicity {
  code: string;
  description?: string;
}

export interface PatientEthnicityInput {
  codes: string[];
  subEthnicity?: string;
}

export interface PatientEthnicityOutput {
  ethnicityTypes: Ethnicity[];
  subEthnicity?: string;
}

export interface BaseProfile {
  uid?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  alternativeLastName?: string;
  dateOfBirth?: string;
  birthGender?: string;
  currentGender?: string;
  phone?: string;
  email?: string;
  mrn?: string;
  language?: Language;
  ethnicities?: PatientEthnicityOutput;
  communicationPreferences?: Communication[];
}

export interface Profile extends BaseProfile {
  addresses?: Address[];
}

export interface SetupProfileData extends BaseProfile {
  addresses?: AddressMatchInput[];
}

export interface ProfileNonAuth {
  uid?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dateOfBirth?: string;
  addresses?: Address[];
}
