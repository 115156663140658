import { TestCard, TestType } from "@app/provider/testData/types";
import { BusinessUnits, DrawRequestTimeslots } from "./drawRequest";
import { MessageDescriptor } from "react-intl";

export enum MobilePhlebotomyStatus {
  DRAFTED = "DRAFTED",
  AVAILABLE = "AVAILABLE",
  CLAIMED = "CLAIMED",
  PUBLISHED = "PUBLISHED",
  ASSIGNED = "ASSIGNED",
  SCHEDULED = "SCHEDULED",
  ON_THE_WAY = "ON_THE_WAY",
  ON_SITE = "ON_SITE",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export interface Physician {
  limsId: number;
  displayName?: string;
  firstName?: string;
  lastName?: string;
}

interface ClinicAdress {
  state?: string;
}

export interface Clinic {
  limsId: number;
  name?: string;
  settings?: {
    testSettings?: [TestSetting];
  };
  address?: ClinicAdress;
}

export interface TestSetting {
  testType: TestType;
  nevaEnabled: boolean;
}

export interface ShippingAddress {
  countryCode?: string;
  city?: string;
  state?: string;
  street?: string;
  street1?: string | null;
  street2?: string | null;
  zipCode?: string;
}

export interface DrawRequest extends DrawRequestTimeslots {
  uid?: string;
  jobStart?: string;
  jobEnd?: string;
  jobStatus: MobilePhlebotomyStatus;
  jobTypes?: string[];
  note?: string;
  shippingAddress?: ShippingAddress;
  jobTimezone?: string;
}

export interface TestDetailsRecord extends TestCard {
  physician: Physician;
  drawRequest?: DrawRequest;
  limsId?: number;
  limsIds?: [string];
  businessUnit: BusinessUnits;
  description?: string;
  jobTypesConcatenated: string;
}

export enum HEAP_EVENTS {
  upp_viewresultpdf_failure = "upp_viewresultpdf_failure",
  upp_viewresultpdf_success = "upp_viewresultpdf_success",
  upp_sampledraw_pref = "upp_sampledraw_pref",
  upp_sampledraw_mp_appt_failure = "upp_sampledraw_mp_appt_failure",
  upp_sampledraw_mp_modifyappt_failure = "upp_sampledraw_mp_modifyappt_failure",
  upp_sampledraw_mp_cancelappt_failure = "upp_sampledraw_mp_cancelappt_failure",
  upp_organiclogin_success = "upp_organiclogin_success",
  upp_organiclogin_failure = "upp_organiclogin_failure",
  upp_tokenizedlogin_success = "upp_tokenizedlogin_success",
  upp_tokenizedlogin_failure = "upp_tokenizedlogin_failure",
  upp_click_testcardcta = "upp_click_testcardcta",
  upp_communication_pref = "upp_communication_pref",
  upp_click_gis = "upp_click_gis",
  upp_click_patientprofile = "upp_click_patientprofile",
  upp_click_testhistory = "upp_click_testhistory",
  upp_click_home = "upp_click_home",
  upp_click_contactnatera = "upp_click_contactnatera",
  upp_video25perc = "upp_video25perc",
  upp_video50perc = "upp_video50perc",
  upp_video75perc = "upp_video75perc",
  upp_video100perc = "upp_video100perc",
  upp_simpleorder_started = "upp_simpleorder_started",
  upp_simpleorder_editpersonalinfo = "upp_simpleorder_editpersonalinfo",
  upp_simpleorder_ethinicity = "upp_simpleorder_ethinicity",
  upp_simpleorder_partnerconsentyes = "upp_simpleorder_partnerconsentyes",
  upp_simpleorder_partnerconsentno = "upp_simpleorder_partnerconsentno",
  upp_simpleorder_fetalsex = "upp_simpleorder_fetalsex",
  upp_simpleorder_payment = "upp_simpleorder_payment",
  upp_simpleorder_insurance = "upp_simpleorder_insurance",
  upp_simpleorder_firstinsurance = "upp_simpleorder_firstinsurance",
  upp_simpleorder_secondinsurance = "upp_simpleorder_secondinsurance",
  upp_simpleorder_costestimate = "upp_simpleorder_costestimate",
  upp_simpleorder_selfpay = "upp_simpleorder_selfpay",
  upp_fetalsexreporting_click = "upp_fetalsexreporting_click",
  upp_fetalsexreporting_downloadcta = "upp_fetalsexreporting_downloadcta",
  upp_fetalsexreporting_copy = "upp_fetalsexreporting_copy",
  upp_fetalsex_hide_on_PDF = "upp_fetalsex_hide_on_PDF",
  upp_fetalsex_view_on_PDF = "upp_fetalsex_view_on_PDF",
  upp_mp_guestflow = "upp_mp_guestflow",
  upp_patientinfo_edit = "upp_patientinfo_edit",
  upp_secondarycard_click = "upp_secondarycard_click",
  upp_viewrenewal_page = "upp_viewrenewal_page",
  upp_renewal_homepage_signatera_click = "upp_renewal_homepage_signatera_click",
  upp_renewal_mp_signatera_click = "upp_renewal_mp_signatera_click",
  download_altera_results_guide = "download_altera_results_guide",
  download_empower_results_guide = "download_empower_results_guide",
  upp_renewal_signatera_yes_interested = "upp_renewal_signatera_yes_interested",
  upp_renewal_signatera_not_interested = "upp_renewal_signatera_not_interested",
  upp_public_url_zip_search = "upp_public_url_zip_search",
}

export enum HeapEventLocation {
  accountCreation = "accountCreation",
  patientProfile = "patientProfile",

  //On Client side:
  burgerMenu = "Burger Menu",
  horizontalMenu = "Horizontal Menu",
  resultDetailPage = "Result Detail Page",
  mp_flow_initiation_page = "MP_flow_initiation_page",
  mp_dob_page = "MP_DOB_page",
  unable_to_verify_mp_page = "Unable_to_verify_MP_page",
  unable_to_verify_unlock_page = "Unable_to_verify_Unlock_page",
  unable_to_schedule_page = "Unable_to_schedule_page",
  consider_before_schedule_pop_up = "Consider_before_schedule_pop_up",
  main_menu = "Main_menu",
  verification_unlock_page = "Verification_unlock_page",
  personal_info_mp_page = "Personal_info_MP_page",
  verification_account_creation_page = "Verification_account_creation_page",
  unable_to_verify_account_creation_page = "Unable_to_verify_account_creation_page",
  broken_link_error = "Broken_link_Error",
  account_activated_error = "Account_activated_Error",
  linking_error = "Linking_Error",
  update_info_error = "Update_info_Error",
  reset_password_error = "Reset_password_Error",
}

export enum HeapVideoContentType {
  renasightPositive = "Renasight_Positive",
  renasightNegative = "Renasight_Negative",
  renasightCarrier = "Renasight_Carrier",
  horizonSimpleOrder = "Horizon_SimpleOrder",
  panoramaSimpleOrder = "Panorama_SimpleOrder",
  prosperaIncreasedRisk = "Prospera_IncreasedRisk",
  prosperaDecreasedRisk = "Prospera_DecreasedRisk",
  prosperaHeartOrLung = "Prospera_HeartOrLung",
  horizonTaySachs = "Horizon_TaySachs",
  horizonDBMuscularDystrophy = "Horizon_DBMuscularDystrophy",
  horizonFragileX = "Horizon_FragileX",
  horizonSMA = "Horizon_SMA",
  horizonCysticFibrosis = "Horizon_CysticFibrosis",
  sampleCollectionSaliva = "SampleCollection_Saliva",
}

export interface HeapVideoOrderData {
  order_uid: string;
  lims_clinic_id?: number;
}

export enum HeapDrawRequestFlow {
  guest = "guest",
  account = "account",
  public = "public",
}

export interface HeapProps {
  business_unit?: string;
  identity?: string;
  test_name?: string;
  lims_clinic_id?: string | number;
  flow?: HeapDrawRequestFlow;
}

export type IntlMessage = { [key: string]: MessageDescriptor };
