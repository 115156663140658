import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { CommunicationsPreferencesForm } from "@app/components/forms";
import PreferenceDialogSvg from "@assets/svg/preference-dialog.svg";
import { Svg } from "@natera/material/lib/svg";
import { Communication } from "@app/provider/profile/types";
import { ProfileContext, PublicMpStepperDataContext } from "@app/provider";
import { DrawRequestScreens } from "../drawRequest";

import "./mpCommunicationsPreferences.scss";

const messages = defineMessages({
  publicMpCommunicationsPreferencesDescription: {
    id: "publicMpCommunicationsPreferencesDescription",
    defaultMessage:
      "Would you like to receive SMS updates regarding your appointment? (Recommended)",
  },
  publicMpCommunicationsPreferencesSubmitBnt: {
    id: "publicMpCommunicationsPreferencesSubmitBnt",
    defaultMessage: "Next",
  },
});

interface PublicMpCommunicationsPreferencesProps {
  setScheduleScreen: React.Dispatch<React.SetStateAction<DrawRequestScreens>>;
}

const PublicMpCommunicationsPreferences: FC<PublicMpCommunicationsPreferencesProps> = ({
  setScheduleScreen,
}) => {
  const intl = useIntl();
  const { updatePatientCommunication } = useContext(ProfileContext);

  const { publicMpToken, publicMpCode } = useContext(
    PublicMpStepperDataContext
  );

  const onSubmit = async (communicationData: Communication[]) => {
    await updatePatientCommunication(communicationData, {
      token: publicMpToken,
    });

    if (publicMpCode === "MP_NO_ORDER_FOUND") {
      setScheduleScreen(DrawRequestScreens.NO_MATCH_ORDER_SCREEN);
    } else {
      setScheduleScreen(DrawRequestScreens.SCHEDULE_BLOOD_DRAW_SCREEN);
    }
  };

  return (
    <div className="public-mp-communications-preferences-wrapper">
      <div className="public-mp-communications-preferences-header">
        <Svg content={PreferenceDialogSvg} />
      </div>
      <div className="public-mp-communications-preferences">
        <div className="public-mp-communications-preferences__content">
          <CommunicationsPreferencesForm
            onSubmit={onSubmit}
            showCancelButton={false}
            submitBtnMessage={intl.formatMessage(
              messages.publicMpCommunicationsPreferencesSubmitBnt
            )}
            descriptionMessage={intl.formatMessage(
              messages.publicMpCommunicationsPreferencesDescription
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default PublicMpCommunicationsPreferences;
