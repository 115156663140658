import React, { FC } from "react";
import AccountCreatedSvg from "@assets/svg/no_active_tests.svg";
import { Svg } from "@natera/material/lib/svg";
import "./noContentView.scss";

interface NoContentViewProps {
  title: React.ReactNode | string;
  message: React.ReactNode | string;
  contactInfo?: React.ReactNode | string;
  action?: React.ReactNode;
  svg?: string;
}

const NoContentView: FC<NoContentViewProps> = ({
  title,
  message,
  action,
  contactInfo,
  svg = AccountCreatedSvg,
}) => {
  return (
    <div className="no-content-view">
      <Svg className="no-content-view_icon" content={svg} />
      <div className="no-content-view_title">{title}</div>
      <div className="no-content-view_subtitle">{message}</div>
      {contactInfo && (
        <div className="no-content-view_contact-info">{contactInfo}</div>
      )}
      <div className="no-content-view_action">{action}</div>
    </div>
  );
};

export default NoContentView;
