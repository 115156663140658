import React from "react";
import Svg from "@natera/material/lib/svg";
import { Document, Page, pdfjs } from "react-pdf";
import { Button } from "@natera/platform/lib/components/form";
import { defineMessages, useIntl } from "react-intl";

import { ConfigContext, ServiceContext } from "@app/provider";
import { TestDetailsContext } from "@app/provider/testData";

import CloudError from "@assets/svg/icons/cloud-error.svg";
import "./pdf.scss";
import { HEAP_EVENTS } from "@app/provider/types";
import { ScrollContext } from "@natera/platform/lib/components/scrollbar";
import { TestType } from "@app/provider/testData/types";
import useIsMobileDimension from "../../hooks/useIsMobileDimension";

// https://github.com/wojtekmaj/react-pdf/issues/97
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const messages = defineMessages({
  pdfDownload: {
    id: "pdfDownload",
    defaultMessage: "Download",
  },
  pdfLoadError: {
    id: "pdfLoadError",
    defaultMessage: "Sorry, we cannot retrieve results right now.",
  },
  pdfSubLoadError: {
    id: "pdfSubLoadError",
    defaultMessage: "Please try again later.",
  },
});

type onDocumentLoadSuccess = (pdf: pdfjs.PDFDocumentProxy) => Promise<void>;
type onPageLoadSuccess = (pdf: pdfjs.PDFPageProxy) => Promise<void>;

type Props = {
  file: string;
  fileName: string;
  downloadHeapEvent?: HEAP_EVENTS;
};

interface LinkDimensions {
  originalTopPosition: number;
  originalLeftPosition: number;
  originalWidth: number;
  originalWidthLaptop?: number;
  originalLeftPositionMobile?: number;
  originalHeight: number;
  url: string;
}

const StaticPdfViewer: React.FC<Props> = ({
  file,
  fileName,
  downloadHeapEvent,
}) => {
  const intl = useIntl();
  const { fileService } = React.useContext(ServiceContext);
  const { getTestDetails } = React.useContext(TestDetailsContext);
  const { config } = React.useContext(ConfigContext);
  const [numPages, setNumPages] = React.useState<number | null>(null);
  const [pdfWidth, setPdfWidth] = React.useState<number | null>(null);
  const [pageWidth, setPageWidth] = React.useState<number | null>(null);
  const isMobile = useIsMobileDimension();
  const refPdfContainer = React.useRef<HTMLDivElement>(null);

  const testDetails = getTestDetails();

  const onDownload = async () => {
    try {
      fileService.downloadStatic(file, fileName);
    } catch (error) {
      console.error(`Failed to download static file: ${file}, error: ${error}`);
    } finally {
      if (testDetails && downloadHeapEvent) {
        heap.track(downloadHeapEvent, {
          order_uid: testDetails.orderUid,
          lims_clinic_id: testDetails.clinic?.limsId,
        });
      }
    }
  };

  const onDocumentLoadSuccess: onDocumentLoadSuccess = async ({
    numPages: nextNumPages,
  }) => {
    setNumPages(nextNumPages);
  };

  const onPageLoadSuccess: onPageLoadSuccess = async (page) => {
    const viewport = page.getViewport({ scale: 1 });
    const width = viewport.width;
    setPageWidth(width);

    const containerWidth = refPdfContainer.current?.clientWidth;

    if (!containerWidth) {
      return;
    }

    setPdfWidth(containerWidth);
  };
  const laptopDimension = 1280;
  const isLaptop = window.innerWidth >= laptopDimension;

  const linkMappings: Record<string, LinkDimensions[]> = {
    ALTERA: [
      {
        originalTopPosition: 424,
        originalLeftPosition: 140,
        originalWidth: 368,
        originalWidthLaptop: 340,
        originalHeight: 57,
        url: config.links.ALTERA_EXPLAINER_VIDEO,
      },
      {
        originalTopPosition: 688,
        originalLeftPosition: 140,
        originalWidth: 547,
        originalWidthLaptop: 495,
        originalHeight: 57,
        url: config.links.SCHEDULE_A_GENETIC_INFORMATION_SESSION,
      },
    ],
    EMPOWER: [
      {
        originalTopPosition: 370,
        originalLeftPosition: 570,
        originalLeftPositionMobile: 650,
        originalWidth: 280,
        originalHeight: 57,
        url: config.links.product.EMPOWER_ONCOLOGY,
      },
      {
        originalTopPosition: 870,
        originalLeftPosition: 620,
        originalLeftPositionMobile: 700,
        originalWidth: 280,
        originalHeight: 57,
        url: config.links.MY_NATERA,
      },
    ],
  };

  const scalePosition = (
    originalPosition: number,
    pageWidth: number | null,
    pdfWidth: number | null
  ) => {
    if (pageWidth && pdfWidth) return (originalPosition / pageWidth) * pdfWidth;
  };

  const scaleLink = (
    link: LinkDimensions,
    pageWidth: number | null,
    pdfWidth: number | null
  ) => ({
    scaledTopPosition: scalePosition(
      link.originalTopPosition,
      pageWidth,
      pdfWidth
    ),
    scaledLeftPosition: scalePosition(
      link.originalLeftPosition,
      pageWidth,
      pdfWidth
    ),
    scaledLeftPositionMobile:
      link.originalLeftPositionMobile &&
      scalePosition(link.originalLeftPositionMobile, pageWidth, pdfWidth),
    scaledWidth: scalePosition(link.originalWidth, pageWidth, pdfWidth),
    scaledWidthLaptop:
      link.originalWidthLaptop &&
      scalePosition(link.originalWidthLaptop, pageWidth, pdfWidth),
    scaledHeight: scalePosition(link.originalHeight, pageWidth, pdfWidth),
    url: link.url,
  });

  const scaledLinks =
    testDetails &&
    linkMappings[testDetails?.testType]?.map((link) =>
      scaleLink(link, pageWidth, pdfWidth)
    );

  return (
    <section className="pdf__container" ref={refPdfContainer}>
      <div className="pdf__container__header">
        {file && (
          <div className="pdf__container__header__title">
            <Button
              className="pdf__container__header__download"
              raised
              onClick={onDownload}
            >
              {intl.formatMessage(messages.pdfDownload)}
            </Button>
          </div>
        )}
      </div>
      <ScrollContext
        component="div"
        isStatic={false}
        settings={{ suppressScrollX: true }}
      >
        {file && (
          <div>
            <div aria-hidden={true}>
              <Document
                className="pdf__container__header__result-pdf"
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {testDetails?.testType === TestType.ALTERA &&
                  Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      className="pdf__container__header__result-pdf-page"
                      pageNumber={index + 1}
                      renderTextLayer
                      renderAnnotationLayer
                      width={pdfWidth ?? 0}
                      onLoadSuccess={onPageLoadSuccess}
                    >
                      {index + 1 === 4 && pdfWidth && pageWidth && (
                        <>
                          {scaledLinks?.map((scaledLink, index) => (
                            <a
                              key={index}
                              href={scaledLink.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="pdf__container__link"
                              style={{
                                position: "absolute",
                                top: `${scaledLink.scaledTopPosition}px`,
                                left: `${scaledLink.scaledLeftPosition}px`,
                                width: isLaptop
                                  ? `${scaledLink.scaledWidthLaptop}px`
                                  : `${scaledLink.scaledWidth}px`,
                                height: `${scaledLink.scaledHeight}px`,
                              }}
                            >
                              &nbsp;
                            </a>
                          ))}
                        </>
                      )}
                    </Page>
                  ))}
                {testDetails?.testType === TestType.EMPOWER &&
                  Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      className="pdf__container__header__result-pdf-page"
                      pageNumber={index + 1}
                      renderTextLayer
                      renderAnnotationLayer
                      width={pdfWidth ?? 0}
                      onLoadSuccess={onPageLoadSuccess}
                    >
                      {index + 1 === 7 && pdfWidth && pageWidth && (
                        <>
                          {scaledLinks?.map((scaledLink, index) => (
                            <a
                              key={index}
                              href={scaledLink.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="pdf__container__link"
                              style={{
                                position: "absolute",
                                top: `${scaledLink.scaledTopPosition}px`,
                                left: isMobile
                                  ? `${scaledLink.scaledLeftPositionMobile}px`
                                  : `${scaledLink.scaledLeftPosition}px`,
                                width: `${scaledLink.scaledWidth}px`,
                                height: `${scaledLink.scaledHeight}px`,
                              }}
                            >
                              &nbsp;
                            </a>
                          ))}
                        </>
                      )}
                    </Page>
                  ))}
              </Document>
            </div>
          </div>
        )}
        {!file && (
          <div className="pdf__container__error">
            <div className="image">
              <Svg content={CloudError} />
            </div>
            <div className="main-text">
              <span>{intl.formatMessage(messages.pdfLoadError)}</span>
            </div>
            <div className="sub-text">
              <span>{intl.formatMessage(messages.pdfSubLoadError)}</span>
            </div>
          </div>
        )}
        <div className="pdf__container__footer">
          {file && (
            <Button
              className="pdf__container__footer__download"
              raised
              onClick={onDownload}
            >
              {intl.formatMessage(messages.pdfDownload)}
            </Button>
          )}
        </div>
      </ScrollContext>
    </section>
  );
};

export default StaticPdfViewer;
