import React from "react";
import { Form } from "@natera/platform/lib/components/form";
import {
  RadioButton,
  RadioGroup,
} from "@natera/platform/lib/components/form/field";
import { Address } from "@app/provider/profile";
import { FormField } from "@app/components";
import { useErrorController } from "@natera/platform/lib/hooks";

import "./selectAddressForm.scss";
import { getAddressAsString } from "@app/utils";

type Props = {
  addresses: Address[];
  onSubmit: (selectedAddress: Address) => void;
  actions: React.ReactNode;
};

const SelectAddressForm: React.FC<Props> = ({
  addresses,
  onSubmit,
  actions,
}) => {
  const [addressUid, setAddressUid] = React.useState<string>(
    addresses[0]?.uid || ""
  );

  const { clearErrors, getValidationError } = useErrorController();

  const handleSelectAddress = (addressUid: string) => setAddressUid(addressUid);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    clearErrors();

    const selectedAddress = addresses.find(
      (address) => address.uid === addressUid
    );

    if (!selectedAddress) {
      return;
    }

    onSubmit(selectedAddress);
  };

  return (
    <Form
      noValidate
      className="patient-addresses"
      buttons={actions}
      onSubmit={handleSubmit}
    >
      <RadioGroup
        name="patientAddressesList"
        value={addressUid}
        onChange={handleSelectAddress}
      >
        {addresses.map((address) => (
          <FormField
            key={address.uid}
            htmlFor={address.uid}
            error={getValidationError(address.uid)}
            withPadding
          >
            <RadioButton
              name={address.uid}
              data-testid={address.uid}
              className="patient-addresses__item"
              label={
                <div className="address-item-desc">
                  {getAddressAsString(address)}
                </div>
              }
              value={address.uid}
            />
          </FormField>
        ))}
      </RadioGroup>
    </Form>
  );
};

export default SelectAddressForm;
