import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { PublicMpStepperDataContext } from "@app/provider";
import { Button } from "@natera/platform/lib/components/form";
import { DrawRequestScreens } from "../drawRequest";

import "./noMatchScreen.scss";

const messages = defineMessages({
  publicMpNoMatchScreenTitle: {
    id: "publicMpNoMatchScreenTitle",
    defaultMessage:
      "Sorry, we couldn't find your matching order, but we can still assist with scheduling.",
  },
  publicMpNoMatchScreenSubtitle: {
    id: "publicMpNoMatchScreenSubtitle",
    defaultMessage: "Do you have a signed order form from your physician?",
  },
  publicMpNoMatchScreenButtonYes: {
    id: "publicMpNoMatchScreenButtonYes",
    defaultMessage: "Yes",
  },
  publicMpNoMatchScreenButtonNo: {
    id: "publicMpNoMatchScreenButtonNo",
    defaultMessage: "No",
  },
});

type NoMatchScreenProps = {
  setScheduleScreen: React.Dispatch<React.SetStateAction<DrawRequestScreens>>;
};

const NoMatchScreen: FC<NoMatchScreenProps> = ({ setScheduleScreen }) => {
  const intl = useIntl();

  const { setPublicMpFailedErrorType } = useContext(PublicMpStepperDataContext);

  const handleClickedYes = () => {
    setScheduleScreen(DrawRequestScreens.SIGNED_ORDER_FORM_SCREEN);
  };

  const handleClickedNo = () => {
    setPublicMpFailedErrorType("NO_SIGNED_ORDER");
    setScheduleScreen(DrawRequestScreens.FAILED_SCREEN);
  };

  return (
    <div className="no-match-screen">
      <div className="no-match-screen__content">
        <div className="no-match-screen-title__container">
          <h2 className="no-match-screen-title">
            {intl.formatMessage(messages.publicMpNoMatchScreenTitle)}
          </h2>
          <h2 className="no-match-screen-subtitle">
            {intl.formatMessage(messages.publicMpNoMatchScreenSubtitle)}
          </h2>
        </div>

        <div className="action-buttons">
          <Button onClick={handleClickedYes} raised>
            {intl.formatMessage(messages.publicMpNoMatchScreenButtonYes)}
          </Button>

          <Button onClick={handleClickedNo} outlined>
            {intl.formatMessage(messages.publicMpNoMatchScreenButtonNo)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NoMatchScreen;
