import React, { FC, useContext, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import LabItem from "@app/components/sampleDraw/steps/labs/labList/labItem";
import { Button } from "@natera/material/lib/button";
import { BloodDrawContext } from "@app/provider";
import { Notification } from "@app/components";
import { ZipSelector } from "@app/components/forms";
import { LoadingContext } from "@natera/platform/lib/components/context";
import { isValidZip, isPuertoRicoZipCode } from "@app/utils";
import { ZipValidationErrors } from "@app/components/forms/zipSelectorForm/zipSelectorForm";
import { DrawRequestScreens } from "../drawRequest";

import "./mpSampleDrawPreference.scss";

const messages = defineMessages({
  publicMpSampleDrawPreferenceTitle: {
    id: "publicMpSampleDrawPreferenceTitle",
    defaultMessage: "Select a Natera-approved local lab near you",
  },
  publicMpSampleDrawPreferenceDescription: {
    id: "publicMpSampleDrawPreferenceDescription",
    defaultMessage:
      "Please call the local lab of your choice to determine if the location accepts walk-ins, or if you need to make an appointment.",
  },
  publicMpSampleDrawPreferenceNotificationText: {
    id: "publicMpSampleDrawPreferenceNotificationText",
    defaultMessage:
      "Please remember to bring your test kit and completed order form from your provider.",
  },
  publicMpSampleDrawPreferenceNoLabs: {
    id: "publicMpSampleDrawPreferenceNoLabs",
    defaultMessage:
      "No location available.{br}<link>Schedule mobile draw</link> instead.",
  },
  publicMpSampleDrawPreferenceDone: {
    id: "publicMpSampleDrawPreferenceDone",
    defaultMessage: "Done with lab selection",
  },
  publicMpSampleDrawPreferenceBack: {
    id: "publicMpSampleDrawPreferenceBack",
    defaultMessage: "Back",
  },
});

export type SampleDrawPreferenceProps = {
  setScheduleScreen: React.Dispatch<React.SetStateAction<DrawRequestScreens>>;
  onOpenAcknowledgementsDialog: () => void;
};

const PublicMpSampleDrawPreference: FC<SampleDrawPreferenceProps> = ({
  setScheduleScreen,
  onOpenAcknowledgementsDialog,
}) => {
  const intl = useIntl();
  const {
    bloodDrawSites,
    getBloodDrawSites,
    isLoading,
    zipCode,
    setZipCode,
  } = useContext(BloodDrawContext);

  let timer: NodeJS.Timeout;

  const [isPuertoRicoLabs, setPuertoRicoLabs] = React.useState<boolean>(false);

  const [
    zipValidationErrorKey,
    setZipValidationErrorKey,
  ] = React.useState<ZipValidationErrors | null>(null);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setZipValidationErrorKey(null);
    setZipCode(event.target.value);
  };

  const goBack = () => {
    setScheduleScreen(DrawRequestScreens.WELCOME_SCREEN);
  };

  const validateZipCode = (zipCode?: string) => {
    if (isPuertoRicoZipCode(zipCode)) {
      setZipValidationErrorKey(ZipValidationErrors.NO_LAB);
      setPuertoRicoLabs(true);
      return false;
    }
    if (zipCode && !isValidZip(zipCode)) {
      setZipValidationErrorKey(ZipValidationErrors.INVALID_ZIP_CODE);
      return false;
    }
    if (zipCode?.length === 0) {
      setZipValidationErrorKey(ZipValidationErrors.EMPTY);
      return false;
    }
    setPuertoRicoLabs(false);
    return true;
  };

  const handleSubmit = (zipCode: string) => {
    setZipValidationErrorKey(null);
    if (validateZipCode(zipCode)) {
      // Debounce for 1 seconds
      timer = setTimeout(() => {
        getBloodDrawSites({ location: zipCode });
      }, 1000);
    }
  };

  useEffect(() => {
    if (zipCode && !isLoading) {
      handleSubmit(zipCode);
    }
    validateZipCode(zipCode);
    return () => clearTimeout(timer);
  }, [zipCode]);

  const noLabsError = () => {
    setZipValidationErrorKey(ZipValidationErrors.NO_LAB);
    setPuertoRicoLabs(true);
  };

  useEffect(() => {
    if (
      isPuertoRicoZipCode(zipCode) ||
      (!bloodDrawSites?.length && !isLoading)
    ) {
      noLabsError();
    } else {
      setZipValidationErrorKey(null);
      setPuertoRicoLabs(false);
    }
  }, [bloodDrawSites, isLoading]);

  const NoLabMessage = () => {
    return (
      <div className="public-mp-sample-draw-preference__content-no-lab">
        <p>
          {intl.formatMessage(messages.publicMpSampleDrawPreferenceNoLabs, {
            br: <br />,
            link: (chunks) => (
              <a onClick={onOpenAcknowledgementsDialog} className="link">
                {chunks}
              </a>
            ),
          })}
        </p>
      </div>
    );
  };

  return (
    <div className="public-mp-sample-draw-preference">
      <div className="public-mp-sample-draw-preference__content">
        <div className="public-mp-sample-draw-preference__content-title">
          {intl.formatMessage(messages.publicMpSampleDrawPreferenceTitle)}
        </div>
        <div className="public-mp-sample-draw-preference__content-description">
          {intl.formatMessage(messages.publicMpSampleDrawPreferenceDescription)}
        </div>

        <Notification
          className={"public-mp-sample-draw-preference__content-note"}
          type="info"
        >
          {intl.formatMessage(
            messages.publicMpSampleDrawPreferenceNotificationText
          )}
        </Notification>

        <div className="public-mp-sample-draw-preference__content-search">
          <ZipSelector
            onChange={onChange}
            zipCode={zipCode}
            zipValidationErrorKey={zipValidationErrorKey}
          />
          {/* paste MilesSelector UPP-3658 */}
        </div>

        <LoadingContext isLoading={isLoading}>
          {bloodDrawSites && bloodDrawSites.length > 0 && !isPuertoRicoLabs ? (
            bloodDrawSites.map((bloodDrawSite) => (
              <LabItem
                bloodDrawSite={bloodDrawSite}
                key={bloodDrawSite.id}
                isPublicMpFlow
              />
            ))
          ) : (
            <NoLabMessage />
          )}
        </LoadingContext>

        <div className="form-buttons">
          <Button raised onClick={goBack}>
            {intl.formatMessage(messages.publicMpSampleDrawPreferenceDone)}
          </Button>
          <Button outlined onClick={goBack}>
            {intl.formatMessage(messages.publicMpSampleDrawPreferenceBack)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PublicMpSampleDrawPreference;
