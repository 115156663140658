import React, { FC, useState } from "react";
import { useErrorController } from "@natera/platform/lib/hooks";
import { StepperContext } from "@natera/stepper";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { StepperContent } from "@app/components/ui/layout/stepperContent";
import {
  PersonalInfoNotification,
  PersonalInfoSteps,
} from "../personalInformation";
import PublicMpZipCodeForm from "@app/components/forms/publicMpForms/publicMpZipCodeForm/publicMpZipCodeForm";
import { messages } from "../index";
import { useIntl } from "react-intl";

export interface ZipCodeData {
  zipCode?: string;
}

const ZipCodeScreen: FC = () => {
  const { resolve, getData } = React.useContext(StepperContext);
  const intl = useIntl();

  const errorController = useErrorController();

  const data = getData(PersonalInfoSteps.ZIP_CODE_SCREEN);

  const formData = data?.zipCodeData as ZipCodeData | undefined;

  const [filledZipCodeData, setFilledZipCodeData] = useState<
    ZipCodeData | undefined
  >(formData);

  const handlePersonalInfoFormSubmit = async (zipCodeData: ZipCodeData) => {
    resolve({ zipCodeData });
  };

  return (
    <>
      <StepperHeaderProgress />

      <StepperContent
        title={intl.formatMessage(
          messages.publicMpPersonalInformationStepThreeTitle
        )}
      >
        <PersonalInfoNotification />

        <PublicMpZipCodeForm
          zipCodeData={filledZipCodeData}
          onSubmit={handlePersonalInfoFormSubmit}
          clearErrors={errorController.clearErrors}
          setValidationError={errorController.setValidationError}
          getValidationError={errorController.getValidationError}
        />
      </StepperContent>
    </>
  );
};

export default ZipCodeScreen;
