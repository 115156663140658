import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Tooltip from "@natera/material/lib/tooltip";
import { Button, Form } from "@natera/platform/lib/components/form";
import { Textfield } from "@natera/platform/lib/components/form/field";
import {
  ClearErrors,
  ClearValidationError,
  GetValidationError,
  SetValidationError,
} from "@natera/platform/lib/hooks/useError";
import { usePhoneValidation } from "@natera/platform/lib/hooks";
import { FormField, PhoneField } from "@app/components";
import { getPhoneValidationErrorMessage, validateEmail } from "@app/utils";
import { ConfigContext, UserContext } from "@app/provider";
import { StepperContext } from "@natera/stepper";
import { ContactInfoData } from "@app/pages/public/drawRequest/personalInformation/stepScreens/contactInfoScreen";
import { messages } from "@app/components/forms/publicMpForms";

interface PublicMpContactInfoFormProps {
  contactInfoData?: ContactInfoData;
  isLoading?: boolean;
  onSubmit: (contactInfoData: ContactInfoData) => void;
  setValidationError: SetValidationError;
  getValidationError: GetValidationError;
  clearErrors: ClearErrors;
  clearValidationError: ClearValidationError;
}

const PublicMpContactInfoForm: FC<PublicMpContactInfoFormProps> = ({
  contactInfoData,
  isLoading,
  onSubmit,
  setValidationError,
  getValidationError,
  clearErrors,
  clearValidationError,
}) => {
  const intl = useIntl();

  const { config } = React.useContext(ConfigContext);

  const usePlusSignInEmailAddress =
    config.test.usePlusSignInEmailAddress.enabled;

  const {
    validatePhoneNumber: validateFromPS,
    isLoading: isLoadingPhoneValidation,
  } = React.useContext(UserContext);
  const phoneValidation = usePhoneValidation();

  const { goBack } = React.useContext(StepperContext);

  const [profileFormData, setProfileFormData] = useState<FormData>();

  const getContactInfoFormData = (formData: FormData) => {
    const email = formData.get("email")?.toString();
    const mobileNumber = formData.get("mobileNumber")?.toString();

    return {
      email,
      mobileNumber,
    };
  };

  const validateProfileFormData = async (formData: FormData) => {
    const profileFormData = getContactInfoFormData(formData);

    const userForm = [
      { title: "email", value: profileFormData.email },
      { title: "mobileNumber", value: profileFormData.mobileNumber },
    ];

    for (const field of userForm) {
      if (!field.value) {
        return setValidationError(
          field.title,
          intl.formatMessage(messages.setupProfileFormRequired)
        );
      }
    }

    if (
      profileFormData.email &&
      !validateEmail(profileFormData.email, usePlusSignInEmailAddress)
    ) {
      setValidationError(
        "email",
        intl.formatMessage(messages.publicMpContactInfoFormEmailInvalid)
      );
      return;
    }

    if (profileFormData.mobileNumber) {
      const phoneValidationErrorMessage = await getPhoneValidationErrorMessage({
        isMandatory: true,
        mobileNumber: profileFormData.mobileNumber,
        intl,
        phoneValidation,
        validateFromPS,
      });

      if (phoneValidationErrorMessage) {
        setValidationError("mobileNumber", phoneValidationErrorMessage);
        return;
      }
    }

    return profileFormData;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    clearErrors();

    const formData = new FormData(e.currentTarget);

    setProfileFormData(formData);

    const validatedProfileFormData = await validateProfileFormData(formData);

    if (validatedProfileFormData) {
      onSubmit({
        email: validatedProfileFormData.email,
        mobileNumber: validatedProfileFormData.mobileNumber,
      });
    }
  };

  useEffect(() => {
    if (profileFormData) {
      validateProfileFormData(profileFormData);
    }
  }, [intl.locale]);

  const handleEmailFieldChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    if (!e.currentTarget.value) {
      clearValidationError("email");
      return;
    }
  };

  const handleBack = () => {
    goBack();
  };

  const loading = isLoading || isLoadingPhoneValidation;

  return (
    <Form
      className="public-mp-personal-information-form__container"
      noValidate
      onSubmit={handleSubmit}
    >
      <FormField
        label={intl.formatMessage(messages.publicMpContactInfoFormEmailField)}
        error={getValidationError("email")}
        htmlFor="email"
        required
        withPadding
      >
        <Tooltip
          content={intl.formatMessage(
            messages.publicMpContactInfoFormEmailFormat
          )}
        >
          <Textfield
            id="email"
            name="email"
            type="email"
            placeholder={intl.formatMessage(
              messages.publicMpContactInfoFormEmailPlaceholder
            )}
            defaultValue={contactInfoData?.email}
            autoComplete="email"
            outline
            onChange={handleEmailFieldChange}
          />
        </Tooltip>
      </FormField>

      <PhoneField
        getValidationError={getValidationError}
        withPadding
        currentPhone={contactInfoData?.mobileNumber}
        required
      />

      <div className="stepper-form-buttons">
        <Button type="submit" raised loading={loading}>
          {intl.formatMessage(messages.setupProfileFormNext)}
        </Button>

        <Button onClick={handleBack} outlined disabled={loading}>
          {intl.formatMessage(messages.publicMpPersonalInfoPageBackButton)}
        </Button>
      </div>
    </Form>
  );
};

export default PublicMpContactInfoForm;
