import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";

import { TestDetailsContext } from "@app/provider";
import { routes } from "@app/routing";
import { StaticPdfViewer } from "@app/components/pdf";
import { BlueHeaderContent } from "@app/components/ui/layout";
import useTestResultsTexts from "@app/hooks/useTestResultsTexts";
import { TestType } from "@app/provider/testData/types";
import { HEAP_EVENTS } from "@app/provider/types";
import AlteraGuidePDF from "@assets/pdf/ALT_OS_PatientResultsGuide_Mobile_20240724_NAT-8021619.pdf";
import EmpowerGuidePDF from "@assets/pdf/EMP_GD_Patient_Guide_to_Results_Mobile_20241004_NAT-8021830_PROOF.pdf";
import "./downloadableResultsGuide.scss";

const messages = defineMessages({
  testCardDownloadableResultsGuide: {
    id: "testCardDownloadableResultsGuide",
    defaultMessage: "Downloadable {product} results guide",
  },
});

interface TestTypeFileEntry {
  file: string;
  fileName: string;
  downloadHeapEvent: HEAP_EVENTS;
}
type TestTypeFileMap = Partial<Record<TestType, TestTypeFileEntry>>;
const testTypeFileMap: TestTypeFileMap = {
  [TestType.ALTERA]: {
    file: AlteraGuidePDF,
    fileName: "Altera_Results_Guide.pdf",
    downloadHeapEvent: HEAP_EVENTS.download_altera_results_guide,
  },
  [TestType.EMPOWER]: {
    file: EmpowerGuidePDF,
    fileName: "Empower_Results_Guide.pdf",
    downloadHeapEvent: HEAP_EVENTS.download_empower_results_guide,
  },
};

const DownloadableResultsGuidePage: React.FC = () => {
  const intl = useIntl();
  const { orderUid, testUid } = useParams<{
    orderUid: string;
    testUid: string;
  }>();

  const { getTestDetails } = useContext(TestDetailsContext);
  const testResultsTexts = useTestResultsTexts();
  const testDetails = getTestDetails();

  const testTitle =
    testDetails?.testType &&
    testResultsTexts.getTestResultDetailsTitle(testDetails?.testType);

  const entry = testDetails?.testType
    ? testTypeFileMap[testDetails.testType]
    : undefined;
  const file = entry?.file ?? "";
  const fileName = entry?.fileName ?? "";
  const downloadHeapEvent = entry?.downloadHeapEvent;

  return (
    <>
      <div
        className="downloadable-results-guide"
        data-testid="test-result-pdf-page"
      >
        <BlueHeaderContent
          blueHeaderTitle={intl.formatMessage(
            messages.testCardDownloadableResultsGuide,
            {
              product: testTitle,
            }
          )}
          crossHeaderRoute={routes.testResultDetailsPage(orderUid, testUid)}
          fullScreen
        >
          <StaticPdfViewer
            file={file}
            fileName={fileName}
            downloadHeapEvent={downloadHeapEvent}
          />
        </BlueHeaderContent>
      </div>
    </>
  );
};

export default DownloadableResultsGuidePage;
