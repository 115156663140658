import React, { FC, useContext } from "react";
import PublicDrawWelcome from "./welcome";
import { PublicMpSampleDrawPreferenceScreen } from "./mpSampleDrawPreference";
import { PersonalInformationScreen } from "./personalInformation";
import { MatchOrderScreen } from "./matchOrder";
import { FailedScreen } from "./failedScreens";
import { NoMatchScreen } from "./noMatch";
import { KitReqFormConfirmationScreen } from "./kitReqFormConfirmation";
import {
  BloodDrawProvider,
  DrawRequestProvider,
  ProfileProvider,
  PublicMpStepperDataProvider,
  HeapAnalyticDataProvider,
  KitProvider,
  OrderRenewalProvider,
  PublicMpStepperDataContext,
} from "@app/provider";
import AcknowledgementsDialog, {
  ACKNOWLEDGMENT_FLOW,
} from "@app/components/acknowledgmentsDialog/acknowledgmentsDialog";
import { useDialog } from "@natera/platform/lib/hooks";
import { PublicMpCommunicationsPreferences } from "./mpCommunicationsPreferences";
import {
  ScheduleBloodDraw,
  ScheduleBloodDrawScreens,
} from "../scheduleBloodDraw/scheduleBloodDraw";
import { DrawRequestFlow } from "@app/provider/drawRequest";
import SignedOrderFormScreen from "./signedOrderFormScreen";

export enum DrawRequestScreens {
  WELCOME_SCREEN = "WELCOME_SCREEN",
  SAMPLE_DRAW_PREFERENCE_SCREEN = "SAMPLE_DRAW_PREFERENCE_SCREEN",
  PERSONAL_INFORMATION_SCREEN = "PERSONAL_INFORMATION_SCREEN",
  MATCH_ORDER_SCREEN = "MATCH_ORDER_SCREEN",
  NO_MATCH_ORDER_SCREEN = "NO_MATCH_ORDER_SCREEN",
  SIGNED_ORDER_FORM_SCREEN = "SIGNED_ORDER_FORM_SCREEN",
  FAILED_SCREEN = "FAILED_SCREEN",
  COMMUNICATIONS_PREFERENCES_SCREEN = "COMMUNICATIONS_PREFERENCES_SCREEN",
  SCHEDULE_BLOOD_DRAW_SCREEN = "SCHEDULE_BLOOD_DRAW_SCREEN",
  KIT_REQ_FORM_CONFIRMATION_SCREEN = "KIT_REQ_FORM_CONFIRMATION_SCREEN",
}

const DrawRequest: FC = () => {
  const [scheduleScreen, setScheduleScreen] = React.useState<
    DrawRequestScreens
  >(DrawRequestScreens.WELCOME_SCREEN);

  const acknowledgementsDialog = useDialog(AcknowledgementsDialog);

  const handleAgreeAcknowledgements = () => {
    acknowledgementsDialog.close();
    setScheduleScreen(DrawRequestScreens.PERSONAL_INFORMATION_SCREEN);
  };

  const handleCloseAcknowledgementsDialog = () => {
    acknowledgementsDialog.close();
  };

  const handleOpenAcknowledgementsDialog = () => {
    acknowledgementsDialog.open({
      userEmail: "",
      onAgreeAcknowledgements: handleAgreeAcknowledgements,
      onCloseDialog: handleCloseAcknowledgementsDialog,
      flow: ACKNOWLEDGMENT_FLOW.GUEST,
    });
  };
  const { publicMpToken } = useContext(PublicMpStepperDataContext);

  const renderScreen = () => {
    switch (scheduleScreen) {
      case DrawRequestScreens.SAMPLE_DRAW_PREFERENCE_SCREEN:
        return (
          <PublicMpSampleDrawPreferenceScreen
            onOpenAcknowledgementsDialog={handleOpenAcknowledgementsDialog}
            setScheduleScreen={setScheduleScreen}
          />
        );
      case DrawRequestScreens.PERSONAL_INFORMATION_SCREEN:
        return (
          <PersonalInformationScreen setScheduleScreen={setScheduleScreen} />
        );
      case DrawRequestScreens.MATCH_ORDER_SCREEN:
        return <MatchOrderScreen setScheduleScreen={setScheduleScreen} />;
      case DrawRequestScreens.FAILED_SCREEN:
        return <FailedScreen />;
      case DrawRequestScreens.NO_MATCH_ORDER_SCREEN:
        return <NoMatchScreen setScheduleScreen={setScheduleScreen} />;
      case DrawRequestScreens.SIGNED_ORDER_FORM_SCREEN:
        return <SignedOrderFormScreen setScheduleScreen={setScheduleScreen} />;
      case DrawRequestScreens.COMMUNICATIONS_PREFERENCES_SCREEN:
        return (
          <PublicMpCommunicationsPreferences
            setScheduleScreen={setScheduleScreen}
          />
        );
      case DrawRequestScreens.SCHEDULE_BLOOD_DRAW_SCREEN:
        return (
          <ScheduleBloodDraw
            flow={DrawRequestFlow.PUBLIC_MP}
            token={publicMpToken}
            initialScreen={ScheduleBloodDrawScreens.KIT_DETAILS_SCREEN}
          />
        );
      case DrawRequestScreens.KIT_REQ_FORM_CONFIRMATION_SCREEN:
        return (
          <KitReqFormConfirmationScreen
            onOpenAcknowledgementsDialog={handleOpenAcknowledgementsDialog}
            setScheduleScreen={setScheduleScreen}
          />
        );
      default:
        return (
          <PublicDrawWelcome
            onOpenAcknowledgementsDialog={handleOpenAcknowledgementsDialog}
            setScheduleScreen={setScheduleScreen}
          />
        );
    }
  };

  return (
    <>
      {renderScreen()}
      {acknowledgementsDialog.getDialog()}
    </>
  );
};

const DrawRequest$: FC = () => {
  return (
    <ProfileProvider>
      <HeapAnalyticDataProvider>
        <BloodDrawProvider>
          <DrawRequestProvider>
            <KitProvider>
              <OrderRenewalProvider>
                <PublicMpStepperDataProvider>
                  <DrawRequest />
                </PublicMpStepperDataProvider>
              </OrderRenewalProvider>
            </KitProvider>
          </DrawRequestProvider>
        </BloodDrawProvider>
      </HeapAnalyticDataProvider>
    </ProfileProvider>
  );
};

export default DrawRequest$;
