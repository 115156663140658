export const isValidZip = (zipCode: string): boolean =>
  /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);

export const isPuertoRicoZipCode = (zipCode?: string): boolean => {
  if (!zipCode) {
    return false;
  }
  const puertoRicoZipRegex = /^(006|007|009)\d{2}(-\d{4})?$/;
  return puertoRicoZipRegex.test(zipCode);
};
