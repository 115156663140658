import React, { FC, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  RadioButton,
  RadioGroup,
} from "@natera/platform/lib/components/form/field";
import "./sampleDrawPreference.scss";
import { Button } from "@natera/platform/lib/components/form";
import { StepperContext } from "@natera/stepper";
import { DRAW_PREFERENCE_TO_SHOW } from "@app/pages/private/sampleDraw/sampleDraw";
import { HEAP_EVENTS } from "@app/provider/types";
import {
  DrawRequestContext,
  HeapAnalyticDataContext,
  TestDetailsContext,
  UppAuthContext,
} from "@app/provider";
import { StepperContent } from "@app/components/ui/layout";
import { SpinnerView } from "@app/components";
import { useDialog } from "@natera/platform/lib/hooks";
import { ClinicRequestModal } from "@app/components/sampleDraw/dialogs";
import { snakeCaseToCapitalizedWords } from "@app/utils";
import { TestType } from "@app/provider/testData/types";
import { businessUnitMapper } from "@app/utils/businessUnitMapper";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";

const messages = defineMessages({
  clinicPreferenceTitle: {
    id: "clinicPreferenceTitle",
    defaultMessage: "My Clinic",
  },
  clinicPreferenceDescription: {
    id: "clinicPreferenceDescription",
    defaultMessage:
      "Contact your clinic directly to see if they offer on-site blood draw services.",
  },
  sampleDrawPreferenceTitle: {
    id: "sampleDrawPreferenceTitle",
    defaultMessage: "Sample Collection",
  },
  sampleDrawPreferenceDescriptionTitle: {
    id: "sampleDrawPreferenceDescriptionTitle",
    defaultMessage: "What is the best way to get a blood draw?",
  },
  sampleDrawPreferenceDescriptionContent: {
    id: "sampleDrawPreferenceDescriptionContent",
    defaultMessage:
      "Natera will cover the cost of your blood draw when you use one of the following methods. Select the method that works best for you.",
  },
  sampleDrawPreferenceMobilePhlebotomyTitle: {
    id: "sampleDrawPreferenceMobilePhlebotomyTitle",
    defaultMessage: "Mobile Blood Draw",
  },
  sampleDrawPreferenceMobilePhlebotomyDescription: {
    id: "sampleDrawPreferenceMobilePhlebotomyDescription",
    defaultMessage:
      "You can have someone come to your desired location to perform your blood draw.",
  },
  sampleDrawPreferenceLabTitle: {
    id: "sampleDrawPreferenceLabTitle",
    defaultMessage: "Local Lab",
  },
  sampleDrawPreferenceLabDescription: {
    id: "sampleDrawPreferenceLabDescription",
    defaultMessage: "Visit a Natera-approved lab to have your blood drawn.",
  },
  sampleDrawPreferenceNext: {
    id: "sampleDrawPreferenceNext",
    defaultMessage: "Next",
  },
  sampleDrawPreferenceBack: {
    id: "sampleDrawPreferenceBack",
    defaultMessage: "Back",
  },
});

export const SAMPLE_DRAW_PREFERENCE_LIST = {
  clinic: {
    title: messages.clinicPreferenceTitle,
    description: messages.clinicPreferenceDescription,
  },
  lab: {
    title: messages.sampleDrawPreferenceLabTitle,
    description: messages.sampleDrawPreferenceLabDescription,
  },
  mobilePhlebotomy: {
    title: messages.sampleDrawPreferenceMobilePhlebotomyTitle,
    description: messages.sampleDrawPreferenceMobilePhlebotomyDescription,
  },
};

export interface SampleDrawPreferenceProps {
  handleSelectSampleDrawPreference: (
    draw_preference?: DRAW_PREFERENCE_TO_SHOW
  ) => void;
  onBack: () => void;
}

const SampleDrawPreference: FC<SampleDrawPreferenceProps> = ({
  handleSelectSampleDrawPreference,
  onBack,
}) => {
  const intl = useIntl();

  const { resolve } = React.useContext(StepperContext);
  const {
    isLoading,
    testCardDetailsData,
    verifiedSampleDrawData,
  } = React.useContext(DrawRequestContext);

  const { drawRequestDataForHeapEventData } = React.useContext(
    HeapAnalyticDataContext
  );

  const { getTestDetails } = React.useContext(TestDetailsContext);
  const { profile } = React.useContext(UppAuthContext);

  const testDetails = getTestDetails();

  const testType =
    testDetails?.testType ??
    verifiedSampleDrawData?.sampleInfo.sample?.testType;
  const clinicModal = useDialog(ClinicRequestModal);
  const isOncologyOrder =
    testDetails?.isOncologyOrder ??
    verifiedSampleDrawData?.sampleInfo.sample?.isOncologyOrder;

  const oncologyJobType = profile
    ? testDetails?.jobTypesConcatenated
    : drawRequestDataForHeapEventData?.job_type;

  const isWHTestType =
    testType &&
    [
      TestType.EMPOWER,
      TestType.HORIZON,
      TestType.PANORAMA,
      TestType.VISTARA,
    ].includes(testType) &&
    !isOncologyOrder;

  const [
    selectedSampleDrawPreference,
    setSelectedSampleDrawPreference,
  ] = React.useState<DRAW_PREFERENCE_TO_SHOW | undefined>(
    isWHTestType ? undefined : DRAW_PREFERENCE_TO_SHOW.lab
  );

  useEffect(() => {
    handleSelectSampleDrawPreference(selectedSampleDrawPreference);
  }, [selectedSampleDrawPreference]);

  const submit = () => {
    const heapProps = {
      lims_clinic_id:
        testCardDetailsData?.clinic.limsId ??
        drawRequestDataForHeapEventData?.lims_clinic_id,
      drawtype:
        selectedSampleDrawPreference ===
        DRAW_PREFERENCE_TO_SHOW.mobilePhlebotomy
          ? "mobile_phlebotomy"
          : selectedSampleDrawPreference,
      test_name: isOncologyOrder
        ? oncologyJobType
        : snakeCaseToCapitalizedWords(testType) ??
          snakeCaseToCapitalizedWords(
            drawRequestDataForHeapEventData?.test_name
          ),
      business_unit:
        businessUnitMapper(testDetails?.businessUnit) ??
        drawRequestDataForHeapEventData?.business_unit,
      flow: profile ? "account" : "guest",
    };
    heap.track(HEAP_EVENTS.upp_sampledraw_pref, heapProps);
    selectedSampleDrawPreference === DRAW_PREFERENCE_TO_SHOW.clinic
      ? clinicModal.open({ goBack: clinicModal.close, isGuest: !profile })
      : resolve({});
  };

  const handleSampleDrawPreferenceSelect = (
    drawPreferenceType: DRAW_PREFERENCE_TO_SHOW
  ) => {
    setSelectedSampleDrawPreference(drawPreferenceType);
  };

  const renderDrawPreferenceItem = (
    drawPreferenceType: DRAW_PREFERENCE_TO_SHOW
  ) => {
    const drawPreferenceText =
      drawPreferenceType in SAMPLE_DRAW_PREFERENCE_LIST &&
      SAMPLE_DRAW_PREFERENCE_LIST[drawPreferenceType];

    return drawPreferenceText ? (
      <RadioButton
        data-testid={drawPreferenceType}
        key={drawPreferenceType}
        className="sample-draw-preference-list__item"
        label={
          <>
            <div className="sample-draw-preference-list__item-title">
              {intl.formatMessage(drawPreferenceText.title)}
            </div>
            <div className="sample-draw-preference-list__item-desc">
              {intl.formatMessage(drawPreferenceText.description)}
            </div>
          </>
        }
        value={drawPreferenceType}
      />
    ) : null;
  };

  const getDrawPreferences = () =>
    Object.keys(DRAW_PREFERENCE_TO_SHOW)
      .filter((drawPreferenceType: DRAW_PREFERENCE_TO_SHOW) => {
        if (!isWHTestType)
          return drawPreferenceType !== DRAW_PREFERENCE_TO_SHOW.clinic;
        return drawPreferenceType;
      })
      .map((drawPreferenceType: DRAW_PREFERENCE_TO_SHOW) =>
        renderDrawPreferenceItem(drawPreferenceType)
      );

  return (
    <div className="sample-draw-preference">
      <SpinnerView isLoading={isLoading} />
      <StepperHeaderProgress />
      <StepperContent
        title={intl.formatMessage(messages.sampleDrawPreferenceTitle)}
        subTitle={intl.formatMessage(
          messages.sampleDrawPreferenceDescriptionTitle
        )}
        description={intl.formatMessage(
          messages.sampleDrawPreferenceDescriptionContent
        )}
      >
        <>
          <div className="sample-draw-preference-list">
            <RadioGroup
              name="sampleDrawPreferenceList"
              value={selectedSampleDrawPreference}
              onChange={handleSampleDrawPreferenceSelect}
            >
              {getDrawPreferences()}
            </RadioGroup>
          </div>
          <div className="form-buttons">
            <Button
              disabled={!selectedSampleDrawPreference}
              raised
              onClick={submit}
            >
              {intl.formatMessage(messages.sampleDrawPreferenceNext)}
            </Button>
            <Button onClick={onBack}>
              {intl.formatMessage(messages.sampleDrawPreferenceBack)}
            </Button>
          </div>
        </>
      </StepperContent>
      {clinicModal.getDialog()}
    </div>
  );
};

export default SampleDrawPreference;
