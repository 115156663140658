import React, { FC, useContext, useMemo } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  ConfigContext,
  RedirectionProvider,
  UppAuthContext,
  UserContext,
} from "@app/provider";
import PublicRouting from "./pages/public/routing";
import PrivateRouting from "./pages/private/routing";
import RedirectLPP, {
  billingPath,
} from "./pages/public/redirectLPP/redirectLpp";
import { TestCardStatus } from "@app/provider/testData/types";
import { SecurityContext } from "@natera/platform/lib/provider/security";
import { LogOut } from "@app/pages";

const root = "";

const getProfileRoutes = () => {
  const personalInformation = `${root}/personal_information`;
  const updatePersonalInformation = `${personalInformation}/update`;
  const contactDetails = `${root}/contact_details`;
  const contactDetailsUpdatePhone = `${contactDetails}/update_phone`;
  const updateEmail = `${root}/update_email`;
  const contactDetailsCreateOrUpdateAddress = `${contactDetails}/create_or_update_address`;
  const securitySettings = `${root}/security_settings`;
  const changePassword = `${root}/change_password`;
  const editMfa = `${root}/edit_mfa`;
  const communicationPreferences = `${root}/communication`;
  const updateCommunicationPreferences = `${communicationPreferences}/update`;

  return {
    personalInformation,
    updatePersonalInformation,
    contactDetails,
    contactDetailsUpdatePhone,
    updateEmail,
    contactDetailsCreateOrUpdateAddress,
    securitySettings,
    changePassword,
    editMfa,
    communicationPreferences,
    updateCommunicationPreferences,
  };
};

export const profileRoutes = getProfileRoutes();

const getLegacyPpRoutes = () => {
  const legacyPpGeneticInfo = `${root}/services/genetic_information`;
  const legacyPpBloodDraw = `${root}/services/blood_draw`;
  const legacyPpCompassionateCare = `${root}/compassionate_care/form`;
  const legacyPpEmpower = `${root}/empower/*`;
  const legacyPpEducation = `${root}/education/*`;
  const legacyPpPricingCompassionateCare = `${root}/pricing/about_compassionate_care`;
  const legacyPpPricingGeneticTesting = `${root}/pricing/genetic_testing`;
  const legacyPpPreferences = `${root}/preferences`;
  const legacyPpBilling = `${root}${billingPath}`;

  return [
    legacyPpGeneticInfo,
    legacyPpBloodDraw,
    legacyPpCompassionateCare,
    legacyPpEmpower,
    legacyPpEducation,
    legacyPpPricingCompassionateCare,
    legacyPpPricingGeneticTesting,
    legacyPpPreferences,
    legacyPpBilling,
  ];
};

const legacyPpRoutes = getLegacyPpRoutes();

const getRoutes = () => {
  const resetPasswordError = `${root}/reset_password_error`;
  const resetPassword = `${root}/reset_password`;
  const resendActivationLink = `${root}/resend_link`;
  const setPassword = `${root}/set_password`;
  const sendEmail = `${root}/send_email`;
  const signIn = `${root}/sign_in`;
  const signUp = `${root}/sign_up`;
  const activation = `${root}/activation`;
  const token = `${root}/token/:token`;
  const home = `${root}/home`;
  const billing = `${root}/billing`;
  const account = `${root}/account`;
  const orders = `${root}/orders`;
  const education = `${root}/education`;
  const profile = `${root}/profile`;
  const help = `${root}/help`;
  const setupProfile = `${root}/setup_profile`;
  const dateOfBirthVerification = `${root}/dob_verification`;
  const confirmation = `${root}/confirmation`;
  const signInInvite = `${signIn}/:invite?`;
  const signUpInvite = `${signUp}/:invite?`;
  const updateEmailError = `${root}/update_email_error`;
  const resendUpdateEmail = `${root}/resend_update_email`;
  const updatePassword = `${root}/update_password`;
  const unlock = `${root}/unlock`;
  const scheduleBloodDraw = `${root}/schedule_blood_draw`;
  const drawRequest = `${root}/services/draw_request`;
  const displayVideo = `${root}/display_video`;
  const test = `${root}/test/:orderUid/:testUid`;
  const scheduleAGeneticInformationSession = `${root}/schedule_a_gis`;
  const testPage = (orderUid: string, testUid: string) =>
    `${root}/test/${orderUid}/${testUid}`;

  const testDetails = `${test}/details`;
  const testDetailsPage = (orderUid: string, testUid: string) =>
    `${testPage(orderUid, testUid)}/details`;

  const testDetailsInformation = `${testDetails}/information`;
  const testDetailsInformationPage = (orderUid: string, testUid: string) =>
    `${testDetailsPage(orderUid, testUid)}/information`;

  const testResultPdf = `${test}/result-pdf/:testDocumentUid`;
  const testResultPdfPage = (
    orderUid: string,
    testUid: string,
    testDocumentUid: string
  ) => `${testPage(orderUid, testUid)}/result-pdf/${testDocumentUid}`;

  const testResultDetails = `${test}/result-details`;
  const testResultDetailsPage = (orderUid: string, testUid: string) =>
    `${testPage(orderUid, testUid)}/result-details`;

  const shareFetalSex = `${test}/share-fetal-sex`;
  const shareFetalSexPage = (orderUid: string, testUid: string) =>
    `${testPage(orderUid, testUid)}/share-fetal-sex`;

  const supplementalPdf = `${test}/pdf/:uid}`;
  const supplementalPdfPage = (
    orderUid: string,
    testUid: string,
    uid: string
  ) => `${testPage(orderUid, testUid)}/pdf/${uid}}`;
  const supplementalVideo = `${test}/video/:uid}`;
  const supplementalVideoPage = (
    orderUid: string,
    testUid: string,
    uid: string
  ) => `${testPage(orderUid, testUid)}/video/${uid}}`;
  const understandingYourResults = `${test}/:testStatus/:testDocumentUid/understanding-your-results/`;
  const understandingYourResultsPage = (
    orderUid: string,
    testUid: string,
    testStatus: TestCardStatus,
    testDocumentUid: string
  ) =>
    `${testPage(
      orderUid,
      testUid
    )}/${testStatus}/${testDocumentUid}/understanding-your-results/`;
  const downloadableResultsGuide = `${test}/downloadable-results-guide/`;
  const downloadableResultsGuidePage = (orderUid: string, testUid: string) =>
    `${testPage(orderUid, testUid)}/downloadable-results-guide/`;
  const sampleDraw = `${root}/sample_draw/:orderUid/:testUid`;
  const sampleDrawModify = `${sampleDraw}/modify`;
  const sampleDrawPage = (orderUid: string, testUid: string) =>
    `${root}/sample_draw/${orderUid}/${testUid}`;
  const sampleDrawModifyPage = (orderUid: string, testUid: string) =>
    `${sampleDrawPage(orderUid, testUid)}/modify`;
  const logOut = `${root}/log_out`;
  const simpleOrder = `${root}/simple_order/:orderUid`;
  const simpleOrderPage = (orderUid: string) =>
    `${root}/simple_order/${orderUid}`;
  const aboutTest = `${simpleOrder}/about_test/:videoType`;
  const aboutTestPage = (orderUid: string, videoType: string) =>
    `${root}/simple_order/${orderUid}/about_test/${videoType}`;
  const testHistory = `${root}/test_history`;

  return {
    root,
    sendEmail,
    resetPassword,
    resetPasswordError,
    resendActivationLink,
    setPassword,
    signIn,
    signUp,
    activation,
    token,
    home,
    billing,
    account,
    orders,
    education,
    profile,
    help,
    setupProfile,
    signInInvite,
    signUpInvite,
    dateOfBirthVerification,
    confirmation,
    updateEmailError,
    resendUpdateEmail,
    updatePassword,
    scheduleBloodDraw,
    drawRequest,
    unlock,
    displayVideo,
    test,
    testPage,
    testDetails,
    testDetailsPage,
    testDetailsInformation,
    testDetailsInformationPage,
    testResultPdf,
    testResultPdfPage,
    testResultDetails,
    testResultDetailsPage,
    shareFetalSex,
    shareFetalSexPage,
    supplementalPdf,
    supplementalPdfPage,
    supplementalVideo,
    supplementalVideoPage,
    understandingYourResults,
    understandingYourResultsPage,
    downloadableResultsGuide,
    downloadableResultsGuidePage,
    logOut,
    sampleDraw,
    sampleDrawModify,
    sampleDrawPage,
    sampleDrawModifyPage,
    simpleOrder,
    simpleOrderPage,
    testHistory,
    aboutTest,
    aboutTestPage,
    scheduleAGeneticInformationSession,
    ...profileRoutes,
  };
};

export const routes = getRoutes();

const Routing: FC = () => {
  const {
    signInByAuthenticationProviderInProgress: signUpByAuthenticationProviderInProgress,
    profile,
  } = useContext(UppAuthContext);
  const { uppUser } = useContext(UserContext);
  const { isAuthorized } = useContext(SecurityContext);
  const { config } = useContext(ConfigContext);

  const legacyPpRedirectionEnabled = config.patientPortal.redirectionEnabled;

  const isAuth = isAuthorized();
  const expiresAt = JSON.parse(
    localStorage.getItem("okta-token-patient") || "{}"
  )?.AUTH_TOKEN?.expiresAt;

  const isExpired = useMemo(
    () => !expiresAt || Date.now() / 1000 - expiresAt > 0,
    [isAuth, signUpByAuthenticationProviderInProgress]
  );

  const user = profile || uppUser;

  return (
    <BrowserRouter>
      <Switch>
        <Route exact component={LogOut} path={routes.logOut} />
        {legacyPpRedirectionEnabled && (
          <Route component={RedirectLPP} path={legacyPpRoutes} />
        )}
        <RedirectionProvider>
          {!isExpired && user && !signUpByAuthenticationProviderInProgress ? (
            <PrivateRouting />
          ) : (
            <PublicRouting />
          )}
        </RedirectionProvider>
      </Switch>
    </BrowserRouter>
  );
};

export default Routing;
