import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "@app/routing";
import { capitalizeFirstLetter, useQuery } from "@app/utils";
import WelcomeGuestScreen from "./welcome";
import WelcomeDobVerificationFailedScreen from "./welcome/welcomeDobVerificationFailedScreen";
import WelcomeDobVerificationScreen from "./welcome/welcomeDobVerificationScreen";
import {
  BloodDrawProvider,
  DrawRequestContext,
  DrawRequestProvider,
  HeapAnalyticDataProvider,
  KitProvider,
  OrderRenewalProvider,
  ProfileProvider,
} from "@app/provider";
import OrderInWrongStateScreen from "@app/pages/public/scheduleBloodDraw/orderInWrongState/OrderInWrongStateScreen";
import { PersonalInformation } from "./personalInformation/personalInformation";
import {
  CreateBloodDrawFailedScreen,
  ScheduleBloodDrawStepper,
} from "./scheduleBloodDrawStepper";
import { KitDetails } from "./kitDetails";
import { ViewBloodDrawDetails } from "./viewBloodDrawDetails";
import TimingInfoDialog from "@app/components/sampleDraw/steps/kit/timingInfoDialog";
import { useDialog } from "@natera/platform/lib/hooks";
import { ComboOrderModal } from "@app/components/sampleDraw/dialogs";
import { DrawRequestFlow } from "@app/provider/drawRequest";

export enum ScheduleBloodDrawScreens {
  WELCOME_GUEST_SCREEN = "WELCOME_GUEST_SCREEN",
  WELCOME_DOB_VERIFY_SCREEN = "WELCOME_DOB_VERIFY_SCREEN",
  WELCOME_DOB_VERIFY_FAILED_SCREEN = "WELCOME_DOB_VERIFY_FAILED_SCREEN",
  SCHEDULE_BLOOD_DRAW_FAILED_SCREEN = "SCHEDULE_BLOOD_DRAW_FAILED_SCREEN",
  PERSONAL_INFO_SCREEN = "PERSONAL_INFO_SCREEN",
  KIT_DETAILS_SCREEN = "KIT_DETAILS_SCREEN",
  MOBILE_PHLEBOTOMY_STEPPER_SCREEN = "MOBILE_PHLEBOTOMY_STEPPER_SCREEN",
  CREATE_BLOOD_DRAW_FAILED_SCREEN = "CREATE_BLOOD_DRAW_FAILED_SCREEN",
  VIEW_BLOOD_DRAW_DETAILS = "VIEW_BLOOD_DRAW_DETAILS",
}

interface ScheduleBloodDraw {
  initialScreen: ScheduleBloodDrawScreens;
  token?: string;
  flow?: DrawRequestFlow;
}

export const ScheduleBloodDraw: FC<ScheduleBloodDraw> = ({
  initialScreen,
  token,
  flow = DrawRequestFlow.GUEST,
}) => {
  const history = useHistory();

  if (!token) {
    history.replace(routes.home);
  }

  const { verifiedSampleDrawData } = React.useContext(DrawRequestContext);

  const comboOrderModal = useDialog(ComboOrderModal);
  const timingDialog = useDialog(TimingInfoDialog);

  const [bloodDrawScreen, setBloodDrawScreen] = React.useState<
    ScheduleBloodDrawScreens
  >(initialScreen);

  const [
    initialDialogsHasBeenProcessed,
    setInitialDialogsHasBeenProcessed,
  ] = React.useState<boolean>(false);

  useEffect(() => {
    const isSampleBloodDrawStepperScreen = [
      ScheduleBloodDrawScreens.KIT_DETAILS_SCREEN,
      ScheduleBloodDrawScreens.MOBILE_PHLEBOTOMY_STEPPER_SCREEN,
    ].includes(bloodDrawScreen);

    if (
      !initialDialogsHasBeenProcessed &&
      isSampleBloodDrawStepperScreen &&
      verifiedSampleDrawData
    ) {
      const isOncologyOrder =
        verifiedSampleDrawData?.sampleInfo.sample?.isOncologyOrder;
      const testTypes =
        verifiedSampleDrawData?.sampleInfo.sample?.testTypes ?? [];

      //timing dialog
      if (isOncologyOrder) {
        const closeTimingDialogAndRedirect = () => {
          timingDialog.close();
          history.length > 1 ? history.goBack() : history.push(routes.home);
        };

        timingDialog.open({
          onClose: closeTimingDialogAndRedirect,
          onContinue: timingDialog.close,
        });
      }

      //combo order dilog
      if (testTypes.length > 1) {
        const orderTypes = testTypes.map((testType) =>
          capitalizeFirstLetter(testType)
        );
        comboOrderModal.open({ orderTypes, onClose: comboOrderModal.close });
      }

      setInitialDialogsHasBeenProcessed(true);
    }
  }, [initialDialogsHasBeenProcessed, bloodDrawScreen, verifiedSampleDrawData]);

  const renderScreen = () => {
    switch (bloodDrawScreen) {
      case ScheduleBloodDrawScreens.WELCOME_DOB_VERIFY_SCREEN:
        return (
          <WelcomeDobVerificationScreen
            token={token as string}
            setBloodDrawScreen={setBloodDrawScreen}
          />
        );
      case ScheduleBloodDrawScreens.WELCOME_DOB_VERIFY_FAILED_SCREEN:
        return <WelcomeDobVerificationFailedScreen />;
      case ScheduleBloodDrawScreens.SCHEDULE_BLOOD_DRAW_FAILED_SCREEN:
        return <OrderInWrongStateScreen />;
      case ScheduleBloodDrawScreens.PERSONAL_INFO_SCREEN:
        return <PersonalInformation setBloodDrawScreen={setBloodDrawScreen} />;
      case ScheduleBloodDrawScreens.KIT_DETAILS_SCREEN:
        return (
          <KitDetails
            token={token as string}
            setBloodDrawScreen={setBloodDrawScreen}
            flow={flow}
          />
        );
      case ScheduleBloodDrawScreens.MOBILE_PHLEBOTOMY_STEPPER_SCREEN:
        return (
          <ScheduleBloodDrawStepper
            token={token as string}
            setBloodDrawScreen={setBloodDrawScreen}
            flow={flow}
          />
        );
      case ScheduleBloodDrawScreens.CREATE_BLOOD_DRAW_FAILED_SCREEN:
        return <CreateBloodDrawFailedScreen />;
      case ScheduleBloodDrawScreens.VIEW_BLOOD_DRAW_DETAILS:
        return <ViewBloodDrawDetails token={token as string} />;
      default:
        return (
          <WelcomeGuestScreen
            token={token as string}
            setBloodDrawScreen={setBloodDrawScreen}
          />
        );
    }
  };

  return (
    <>
      {renderScreen()}
      {timingDialog.getDialog()}
      {comboOrderModal.getDialog()}
    </>
  );
};

const ScheduleBloodDraw$: FC = () => {
  const query = useQuery();
  const token = query.get("token") ?? undefined;

  return (
    <HeapAnalyticDataProvider>
      <BloodDrawProvider>
        <DrawRequestProvider>
          <ProfileProvider>
            <KitProvider>
              <OrderRenewalProvider>
                <ScheduleBloodDraw
                  initialScreen={ScheduleBloodDrawScreens.WELCOME_GUEST_SCREEN}
                  token={token}
                />
              </OrderRenewalProvider>
            </KitProvider>
          </ProfileProvider>
        </DrawRequestProvider>
      </BloodDrawProvider>
    </HeapAnalyticDataProvider>
  );
};

export default ScheduleBloodDraw$;
