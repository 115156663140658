import React, { FC, useCallback, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import classNames from "classnames";
import { Notification } from "@app/components";
import { IconButton } from "@natera/material/lib/button";
import { stripHtmlTags } from "@app/utils/common";
import { LinkButton } from "@natera/platform/lib/components/form";
import { BloodDrawSite } from "@app/provider";
import Arrow from "@assets/svg/icons/lab-arrow.svg";
import "./labItem.scss";

const messages = defineMessages({
  labCollectionLabListLabItemDistance: {
    id: "labCollectionLabListLabItemDistance",
    defaultMessage: "{distance} mi away",
  },
  labCollectionLabListLabItemClosed: {
    id: "labCollectionLabListLabItemClosed",
    defaultMessage: "Closed",
  },
  labCollectionLabListLabItemExpand: {
    id: "labCollectionLabListLabItemExpand",
    defaultMessage: "Expand",
  },
  labCollectionLabListLabItemCollapse: {
    id: "labCollectionLabListLabItemCollapse",
    defaultMessage: "Collapse",
  },
  scheduleAppointmentButton: {
    id: "scheduleAppointmentButton",
    defaultMessage: "Schedule appointment",
  },
});

interface LabItemProps {
  bloodDrawSite: BloodDrawSite;
  isPublicMpFlow?: boolean;
}

const LabItem: FC<LabItemProps> = ({ bloodDrawSite, isPublicMpFlow }) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const { distance, name, full_address, phone, site_hours } = bloodDrawSite;

  const toggle = useCallback(() => {
    setIsOpen((value) => !value);
  }, [isOpen]);

  const publicMpContent = () => {
    return (
      <>
        {bloodDrawSite?.comments && (
          <Notification className={"lab-item__info__note"} type="note">
            <b>{stripHtmlTags(bloodDrawSite.comments)}</b>
          </Notification>
        )}
        {bloodDrawSite?.appointment_scheduling_url && (
          <LinkButton
            outlined
            to={{ pathname: bloodDrawSite.appointment_scheduling_url }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {intl.formatMessage(messages.scheduleAppointmentButton)}
          </LinkButton>
        )}
      </>
    );
  };

  return (
    <div className="lab-item">
      <div
        className={classNames("lab-item__arrow", {
          "lab-item__arrow--visible": isOpen,
        })}
      >
        <IconButton
          onClick={toggle}
          aria-label={intl.formatMessage(
            isOpen
              ? messages.labCollectionLabListLabItemCollapse
              : messages.labCollectionLabListLabItemExpand
          )}
        >
          {Arrow}
        </IconButton>
      </div>

      <div className="lab-item__info">
        <div className="lab-item__info__distance">
          {intl.formatMessage(messages.labCollectionLabListLabItemDistance, {
            distance: distance.toFixed(0),
          })}
        </div>

        <div className="lab-item__info__name">{name}</div>

        <div className="lab-item__info__fulladdress">{full_address}</div>

        <div
          className={classNames("lab-item__info__wrapper", {
            "lab-item__info__wrapper--visible": isOpen,
          })}
        >
          <div className="lab-item__info__wrapper__phone">
            <a href={`tel:${phone}`}>{phone}</a>
          </div>

          <div className="lab-item__info__wrapper__hours">
            {site_hours.map((siteHour) => (
              <div className="site-hour" key={siteHour.id}>
                <span className="site-hour__day">{siteHour.day_of_week}</span>

                <span className="site-hour__time">
                  {!siteHour.close && !siteHour.open
                    ? intl.formatMessage(
                        messages.labCollectionLabListLabItemClosed
                      )
                    : `${siteHour.open} - ${siteHour.close}`}
                </span>
              </div>
            ))}
          </div>

          {isPublicMpFlow && publicMpContent()}
        </div>
      </div>
    </div>
  );
};

export default LabItem;
