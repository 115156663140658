import React, { FC, useState } from "react";
import { useErrorController } from "@natera/platform/lib/hooks";
import { DrawRequestScreens } from "../../drawRequest";
import { StepperContext } from "@natera/stepper";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import PublicMpNameForm from "@app/components/forms/publicMpForms/publicMpNameForm/publicMpNameForm";
import {
  PersonalInfoNotification,
  PersonalInfoSteps,
} from "../personalInformation";
import { StepperContent } from "@app/components/ui/layout/stepperContent";
import { messages } from "../index";
import { useIntl } from "react-intl";

export interface PersonalInfoData {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  alternativeLastName?: string;
}

type PersonalInformationProps = {
  setScheduleScreen: React.Dispatch<React.SetStateAction<DrawRequestScreens>>;
};

const NameScreen: FC<PersonalInformationProps> = ({ setScheduleScreen }) => {
  const { resolve, getData } = React.useContext(StepperContext);
  const intl = useIntl();

  const data = getData(PersonalInfoSteps.NAME_SCREEN);

  const formData = data?.nameData as PersonalInfoData | undefined;

  const [filledProfileData, setFilledProfileData] = useState<
    PersonalInfoData | undefined
  >(formData);

  const errorController = useErrorController();

  const handlePersonalInfoFormSubmit = async (
    personalInfoData: PersonalInfoData
  ) => {
    resolve({ nameData: personalInfoData });
  };

  return (
    <>
      <StepperHeaderProgress />

      <StepperContent
        title={intl.formatMessage(
          messages.publicMpPersonalInformationStepOneTitle
        )}
      >
        <PersonalInfoNotification />

        <PublicMpNameForm
          setScheduleScreen={setScheduleScreen}
          personalInfoData={filledProfileData}
          onSubmit={handlePersonalInfoFormSubmit}
          clearErrors={errorController.clearErrors}
          setValidationError={errorController.setValidationError}
          getValidationError={errorController.getValidationError}
        />
      </StepperContent>
    </>
  );
};

export default NameScreen;
