import React, { FC, useState } from "react";
import { useErrorController } from "@natera/platform/lib/hooks";
import { StepperContext } from "@natera/stepper";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import {
  PersonalInfoNotification,
  PersonalInfoSteps,
} from "../personalInformation";
import { StepperContent } from "@app/components/ui/layout/stepperContent";
import PublicMpContactInfoForm from "@app/components/forms/publicMpForms/publicMpContactInfoForm/publicMpContactInfoForm";
import { messages } from "../index";
import { useIntl } from "react-intl";

export interface ContactInfoData {
  email?: string;
  mobileNumber?: string;
}

const ContactInfoScreen: FC = () => {
  const { resolve, getData } = React.useContext(StepperContext);
  const intl = useIntl();

  const errorController = useErrorController();

  const data = getData(PersonalInfoSteps.CONTACT_INFO_SCREEN);

  const formData = data?.contactInfoData as ContactInfoData | undefined;

  const [filledContactInfoData, setFilledContactInfoData] = useState<
    ContactInfoData | undefined
  >(formData);

  const handlePersonalInfoFormSubmit = async (
    contactInfoData: ContactInfoData
  ) => {
    resolve({ contactInfoData });
  };

  return (
    <>
      <StepperHeaderProgress />

      <StepperContent
        title={intl.formatMessage(
          messages.publicMpPersonalInformationStepFourTitle
        )}
      >
        <PersonalInfoNotification />

        <PublicMpContactInfoForm
          contactInfoData={filledContactInfoData}
          onSubmit={handlePersonalInfoFormSubmit}
          clearErrors={errorController.clearErrors}
          setValidationError={errorController.setValidationError}
          getValidationError={errorController.getValidationError}
          clearValidationError={errorController.clearValidationError}
        />
      </StepperContent>
    </>
  );
};

export default ContactInfoScreen;
