import { DocumentNode, gql } from "@apollo/client";

class TestCardService {
  public static getTestCards = (): DocumentNode => gql`
    query GetTestCards {
      getTestCards {
        uid
        orderUid
        testType
        status
        nextStatus
        managedBy
        sampleCollectionDate
        isProReach
        sampleType
        sampleReceivedDate
        PTPOption
        viewStatus
        organType
        createdAt
        resultReleasedAt
        latestResultDocumentUid
        trackingNumber
        reportGender
        caPnsSupplemental
        resultCode
        fetalSex
        clinic {
          limsId
        }
        isComboOrder
        isNevaEnabled
        isOrderInSignedOrdersTable
        simpleOrderWorkflowState
        isSignateraSubsequent
        isEmpowerStatPanelIncluded
        isLatestReleasedResultStat
        isStatResultAlreadyViewed
      }
    }
  `;

  public static getTestCardDetails = (): DocumentNode => gql`
    query GetTestCardDetails($orderUid: String!, $testUid: String!) {
      getTestCardDetails(orderUid: $orderUid, testUid: $testUid) {
        uid
        businessUnit
        jobTypesConcatenated
        orderUid
        testType
        status
        nextStatus
        managedBy
        sampleCollectionDate
        isProReach
        sampleType
        sampleReceivedDate
        PTPOption
        viewStatus
        organType
        createdAt
        resultReleasedAt
        latestResultDocumentUid
        trackingNumber
        kitShippedDate
        reportGender
        caPnsSupplemental
        resultCode
        fetalSex
        physician {
          displayName
          firstName
          lastName
        }
        clinic {
          limsId
          name
        }
        limsId
        limsIds
        drawRequest {
          uid
          jobStart
          jobEnd
          jobStatus
          jobTypes
          note
          shippingAddress {
            city
            street
            state
            zipCode
          }
          start1
          start2
          start3
          end1
          end2
          end3
          jobTimezone
        }
        isComboOrder
        isNevaEnabled
        isOrderInSignedOrdersTable
        simpleOrderWorkflowState
        documentReferenceId
        isSignateraSubsequent
        isOncologyOrder
        isEmpowerStatPanelIncluded
        isLatestReleasedResultStat
        isStatResultAlreadyViewed
      }
    }
  `;

  public static getSecondaryCards = (): DocumentNode => gql`
    query GetSecondaryCards {
      getSecondaryCards {
        type
        limsClinicId
      }
    }
  `;

  public static getTestResult = (): DocumentNode => gql`
    query GetTestResult($orderUid: String!, $testUid: String!) {
      getTestResult(orderUid: $orderUid, testUid: $testUid) {
        orderUid
        testUid
        testType
        organ
        reportGender
        caPnsSupplemental
        resultCode
        latestResultDocumentUid
        resultDocuments {
          releasedToProviderDate
          releasedToPatientDate
          resultDate
          providerNotes
          document {
            uid
            url
            documentName
            documentType
            contentType
            documentReferenceId
          }
        }
      }
    }
  `;

  public static getTestResultInfoFromToken = (): DocumentNode => gql`
    query GetTestResultInfoFromToken($token: String!) {
      getTestResultInfoFromToken(token: $token) {
        orderUID
        testUid
      }
    }
  `;

  public static isUserHasArchivedTests = (): DocumentNode => gql`
    query IsUserHasArchivedTests {
      isUserHasArchivedTests
    }
  `;
}

export default TestCardService;
