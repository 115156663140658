import { ParsedTestCard } from "@app/provider/testData/types";
import { IntlShape } from "react-intl/src/types";

export const getResultReleasedAtMessage = (
  testCard: ParsedTestCard,
  intl: IntlShape,
  receivedOn: { id: string; defaultMessage: string },
  received: { id: string; defaultMessage: string }
): string => {
  return testCard.resultReleasedAt
    ? intl.formatMessage(receivedOn, {
        date: new Date(testCard.resultReleasedAt).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
      })
    : intl.formatMessage(received);
};
