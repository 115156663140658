import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import NoContentView from "@app/pages/private/noContentView";
import { ConfigContext, PublicMpStepperDataContext } from "@app/provider";
import { HEAP_EVENTS } from "@app/provider/types";
import { LinkHeap } from "@app/components";
import DocumentsSpeakerSvg from "@assets/svg/documents-speaker-filled.svg";
import DocumentsDoneSvg from "@assets/svg/documents-done-filled.svg";
import DocumentsMagnifyingGlass from "@assets/svg/documents-magnifying-glass.svg";

import "./mpFailedScreen.scss";

const messages = defineMessages({
  publicMpFailedScreenNoRequiredDescription: {
    id: "publicMpFailedScreenNoRequiredDescription",
    defaultMessage:
      "We could not find an order that required a mobile draw in our system.",
  },
  publicMpFailedScreenNoMatchingDescription: {
    id: "publicMpFailedScreenNoMatchingDescription",
    defaultMessage:
      "We're sorry. We are unable to submit your appointment request at this time.",
  },
  publicMpFailedScreenAppointmentExistDescription: {
    id: "publicMpFailedScreenAppointmentExistDescription",
    defaultMessage:
      "There is already a mobile draw appointment scheduled for the order.",
  },
  publicMpFailedScreenNoSignedFormSelectedDescription: {
    id: "publicMpFailedScreenNoSignedFormSelectedDescription",
    defaultMessage: "Natera genetic tests must be ordered through a physician.",
  },
  publicMpFailedScreenScreenContact: {
    id: "publicMpFailedScreenScreenContact",
    defaultMessage: "Any questions? {contactUs}",
  },
  publicMpFailedScreenScreenContactUs: {
    id: "publicMpFailedScreenScreenContactUs",
    defaultMessage: "Contact us",
  },
});

const MpFailedScreen: FC = () => {
  const intl = useIntl();

  const { config } = React.useContext(ConfigContext);
  const { publicMpFailedErrorType } = useContext(PublicMpStepperDataContext);

  const getTitle = () => {
    switch (publicMpFailedErrorType) {
      case "MP_NO_MATCHING_ORDER":
        return intl.formatMessage(
          messages.publicMpFailedScreenNoRequiredDescription
        );
      case "MP_ACTIVE_ALREADY_EXISTS":
        return intl.formatMessage(
          messages.publicMpFailedScreenAppointmentExistDescription
        );
      case "NO_SIGNED_ORDER":
        return intl.formatMessage(
          messages.publicMpFailedScreenNoSignedFormSelectedDescription
        );
      default:
        return intl.formatMessage(
          messages.publicMpFailedScreenNoMatchingDescription
        );
    }
  };

  const getSvg = () => {
    switch (publicMpFailedErrorType) {
      case "MP_ACTIVE_ALREADY_EXISTS":
        return DocumentsDoneSvg;
      case "NO_SIGNED_ORDER":
        return DocumentsMagnifyingGlass;
      default:
        return DocumentsSpeakerSvg;
    }
  };

  const getMessage = () => {
    return publicMpFailedErrorType === "NO_SIGNED_ORDER" ? (
      <></>
    ) : (
      intl.formatMessage(messages.publicMpFailedScreenScreenContact, {
        contactUs: (
          <LinkHeap
            target="_blank"
            key="privacyPolicy"
            rel="noopener noreferrer"
            to={{ pathname: config.links.CONTACT_US }}
            heapEventName={HEAP_EVENTS.upp_click_contactnatera}
          >
            {intl.formatMessage(messages.publicMpFailedScreenScreenContactUs)}
          </LinkHeap>
        ),
      })
    );
  };

  return (
    <div className="mp-failed-screen">
      <NoContentView svg={getSvg()} title={getTitle()} message={getMessage()} />
    </div>
  );
};

export default MpFailedScreen;
