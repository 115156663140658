import * as React from "react";
import { IntlProvider } from "react-intl";

export enum Language {
  EN = "en",
  ES = "es",
}

export type LanguageKeys = keyof typeof Language;

type ChangeLanguage = (code: Language) => void;

export interface IntlController {
  changeLanguage: ChangeLanguage;
  currentLanguage: Language;
}

export const Context = React.createContext<IntlController>({
  changeLanguage: () => undefined,
  currentLanguage: Language.EN,
});

const IntlWrapper: React.FC = ({ children }) => {
  const [intlProps, setIntlProps] = React.useState({
    messages: {},
    locale: Language.EN,
  });

  React.useEffect(
    () =>
      document.documentElement.setAttribute(
        "lang",
        intlProps.locale === Language.EN
          ? `${Language.EN}-US`
          : intlProps.locale
      ),
    [intlProps.locale]
  );

  const loadLocaleData = (locale: Language) => {
    switch (locale) {
      case Language.ES:
        return import("@languages/es.json");
      default:
        return import("@languages/en.json");
    }
  };

  const changeIntlProps = (code: Language) => {
    loadLocaleData(code).then((data) => {
      setIntlProps({
        messages: data,
        locale: code,
      });
    });
  };

  const changeLanguage: ChangeLanguage = (code) => {
    changeIntlProps(code);
  };

  React.useEffect(() => {
    changeIntlProps(intlProps.locale);
  }, []);

  return (
    <Context.Provider
      value={{
        changeLanguage,
        currentLanguage: intlProps.locale,
      }}
    >
      <IntlProvider {...intlProps} defaultLocale={Language.EN}>
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default IntlWrapper;
