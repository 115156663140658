import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/platform/lib/components/form";
import useIsMobileDimension from "../../hooks/useIsMobileDimension";
import { ConfigContext } from "@app/provider";

import "./organHealthSchedulingDialog.scss";

const messages = defineMessages({
  ohSchedulingDialogTitle: {
    id: "ohSchedulingDialogTitle",
    defaultMessage: "Scheduling",
  },
  ohSchedulingDialogContent: {
    id: "ohSchedulingDialogContent",
    defaultMessage: "Please call {phone} to schedule your draw",
  },
  ohSchedulingDialogCallButton: {
    id: "ohSchedulingDialogCallButton",
    defaultMessage: "Call now",
  },
  ohSchedulingDialogCopyButton: {
    id: "ohSchedulingDialogCopyButton",
    defaultMessage: "Copy number",
  },
});

const OrganHealthSchedulingDialog: FC<{ onCloseDialog: () => void }> = ({
  onCloseDialog,
}) => {
  const intl = useIntl();
  const { config } = useContext(ConfigContext);

  const PHONE_NUMBER = config.phones.ORGAN_HEALTH_DRAW_REQUEST_SCHEDULING;

  const isMobile = useIsMobileDimension();

  const copyNumber = async () => {
    await navigator.clipboard.writeText(PHONE_NUMBER);
  };

  return (
    <Dialog
      onClose={onCloseDialog}
      title={intl.formatMessage(messages.ohSchedulingDialogTitle)}
      showCrossButton
      actions={
        <>
          {isMobile ? (
            <a
              className="oh-scheduling-dialog__mobile-call"
              href={`tel:${PHONE_NUMBER}`}
            >
              <Button
                className="oh-scheduling-dialog__mobile-call-button"
                raised
              >
                {intl.formatMessage(messages.ohSchedulingDialogCallButton)}
              </Button>
            </a>
          ) : (
            <Button raised onClick={copyNumber}>
              {intl.formatMessage(messages.ohSchedulingDialogCopyButton)}
            </Button>
          )}
        </>
      }
    >
      <div className="oh-scheduling-dialog__container">
        <p>
          {intl.formatMessage(messages.ohSchedulingDialogContent, {
            phone: PHONE_NUMBER,
          })}
        </p>
      </div>
    </Dialog>
  );
};

export default OrganHealthSchedulingDialog;
