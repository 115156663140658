import { defineMessages } from "react-intl";

export const messages = defineMessages({
  publicMpPersonalInformationStepOneTitle: {
    id: "publicMpPersonalInformationStepOneTitle",
    defaultMessage:
      "Great! We'll ask a few questions to find the order. What is your name?",
  },
  publicMpPersonalInformationStepTwoTitle: {
    id: "publicMpPersonalInformationStepTwoTitle",
    defaultMessage: "What is your date of birth?",
  },
  publicMpPersonalInformationStepThreeTitle: {
    id: "publicMpPersonalInformationStepThreeTitle",
    defaultMessage: "What is your zip code?",
  },
  publicMpPersonalInformationStepFourTitle: {
    id: "publicMpPersonalInformationStepFourTitle",
    defaultMessage: "What is your contact information?",
  },
  publicMpPersonalInformationConfirmationTitle: {
    id: "publicMpPersonalInformationConfirmationTitle",
    defaultMessage: "Confirm your information",
  },
  publicMpPersonalInformationFormNotificationText: {
    id: "publicMpPersonalInformationFormNotificationText",
    defaultMessage:
      "Information entered should match what is on file with your clinic, which normally matches what is on your insurance card.",
  },
});
