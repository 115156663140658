import React from "react";
import { Form } from "@natera/platform/lib/components/form";
import { FormField } from "@app/components";
import { useErrorController } from "@natera/platform/lib/hooks";
import { defineMessages, useIntl } from "react-intl";
import { isValidZip } from "@app/utils";
import Tooltip from "@natera/material/lib/tooltip";
import { HeapPHIDecorator } from "@app/components/analytics";
import { Textfield } from "@natera/material/lib/textfield";
import { IntlMessage } from "@app/provider/types";

import "./zipSelectorForm.scss";

const messages: IntlMessage = defineMessages({
  labCollectionZipSelectorPlaceholder: {
    id: "labCollectionZipSelectorPlaceholder",
    defaultMessage: "Enter Your Zip Code",
  },
  labCollectionZipSelectorTooltip: {
    id: "labCollectionZipSelectorTooltip",
    defaultMessage:
      "Required format: 5-digit number, or 9-digit number with a dash after the 5th digit XXXXX or XXXXX-XXXX",
  },
  labCollectionZipSelectorZipCodeLabel: {
    id: "labCollectionZipSelectorZipCodeLabel",
    defaultMessage: "Zip Code",
  },
  labCollectionZipSelectorInvalidZipCode: {
    id: "labCollectionZipSelectorInvalidZipCode",
    defaultMessage: "Please enter a valid zip code",
  },
  labCollectionZipSelectorNoLabs: {
    id: "labCollectionZipSelectorNoLabs",
    defaultMessage: "No labs found in the specified location.",
  },
  labCollectionZipSelectorRequired: {
    id: "labCollectionZipSelectorRequired",
    defaultMessage: "This information is required.",
  },
});

export enum ZipValidationErrors {
  INVALID_ZIP_CODE = "INVALID_ZIP_CODE",
  NO_LAB = "NO_LAB",
  EMPTY = "EMPTY",
}

const errorMessagesMapper: Record<
  ZipValidationErrors,
  keyof typeof messages
> = {
  [ZipValidationErrors.INVALID_ZIP_CODE]:
    "labCollectionZipSelectorInvalidZipCode",
  [ZipValidationErrors.NO_LAB]: "labCollectionZipSelectorNoLabs",
  [ZipValidationErrors.EMPTY]: "labCollectionZipSelectorRequired",
};

type Props = {
  onSubmit: (zipCode: string) => void;
  actions: React.ReactNode;
  zipValidationErrorKey: ZipValidationErrors | null;
  setZipValidationErrorKey: React.Dispatch<
    React.SetStateAction<ZipValidationErrors | null>
  >;
};

const ZipSelectorForm: React.FC<Props> = ({
  onSubmit,
  actions,
  zipValidationErrorKey,
  setZipValidationErrorKey,
}) => {
  const intl = useIntl();
  const [zipCode, setZipCode] = React.useState("");

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setZipValidationErrorKey(null);
    setZipCode(event.target.value);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    setZipValidationErrorKey(null);

    if (isValidZip(zipCode)) {
      onSubmit(zipCode);
    } else {
      zipCode.length === 0
        ? setZipValidationErrorKey(ZipValidationErrors.EMPTY)
        : setZipValidationErrorKey(ZipValidationErrors.INVALID_ZIP_CODE);
    }
  };

  return (
    <Form noValidate onSubmit={handleSubmit} buttons={actions}>
      <ZipSelector
        label={intl.formatMessage(
          messages.labCollectionZipSelectorZipCodeLabel
        )}
        zipCode={zipCode}
        onChange={onChange}
        zipValidationErrorKey={zipValidationErrorKey}
      />
    </Form>
  );
};

type ZipSelectorProps = {
  zipCode?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  zipValidationErrorKey?: ZipValidationErrors | null;
  label?: string;
};

export const ZipSelector: React.FC<ZipSelectorProps> = ({
  zipCode,
  onChange,
  zipValidationErrorKey,
  label,
}) => {
  const errorController = useErrorController();
  const intl = useIntl();

  React.useEffect(() => {
    if (zipValidationErrorKey) {
      const messageDescriptor =
        messages[errorMessagesMapper[zipValidationErrorKey]];

      errorController.setValidationError(
        "zip",
        intl.formatMessage(messageDescriptor)
      );
    } else {
      errorController.clearValidationError("zip");
    }
  }, [intl.locale, zipValidationErrorKey]);

  return (
    <FormField
      error={errorController.getValidationError("zip")}
      fullWidth
      label={label}
      htmlFor="zip"
      className="zipSelectorForm"
      withPadding
    >
      <Tooltip
        content={intl.formatMessage(messages.labCollectionZipSelectorTooltip)}
      >
        <div>
          <HeapPHIDecorator protectAttr={["value"]} inline>
            <Textfield
              className="zip-selector__form__input"
              placeholder={intl.formatMessage(
                messages.labCollectionZipSelectorPlaceholder
              )}
              id="zip"
              name="zip"
              outline
              value={zipCode}
              onChange={onChange}
              invalid={!!errorController.getValidationError("zip")}
              formNoValidate
              aria-label="Zip Code"
            />
          </HeapPHIDecorator>
        </div>
      </Tooltip>
    </FormField>
  );
};

export default ZipSelectorForm;
