import React, { FC, useContext, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  DrawRequestContext,
  ProfileContext,
  PublicMpStepperDataContext,
  IntlContext,
} from "@app/provider";
import DocumentsMagnifyingGlass from "@assets/svg/documents-magnifying-glass.svg";
import { DrawRequestScreens } from "../drawRequest";
import Svg from "@natera/material/lib/svg";
import { PublicMpFailedErrorType } from "@app/provider/publicMpStepperData";
import { MatchPatientNonAuthResponse } from "@app/provider/drawRequest";
import { convertPhoneNumberToE164 } from "@app/utils/convertPhoneNumber";

import "./matchOrder.scss";

const messages = defineMessages({
  matchOrderLoadingText: {
    id: "matchOrderLoadingText",
    defaultMessage: "Looking for your order...",
  },
});

const errorMapping: Record<string, string> = {
  MP_ACTIVE_ALREADY_EXISTS: "MP_ACTIVE_ALREADY_EXISTS",
  MP_NO_MATCHING_ORDER: "MP_NO_MATCHING_ORDER",
  MP_NO_PATIENT_MATCH: "MP_NO_PATIENT_MATCH",
};

type MatchOrderProps = {
  setScheduleScreen: React.Dispatch<React.SetStateAction<DrawRequestScreens>>;
};

const MatchOrder: FC<MatchOrderProps> = ({ setScheduleScreen }) => {
  const intl = useIntl();
  const { currentLanguage } = useContext(IntlContext);
  const {
    matchPatientNonAuthRequest,
    verifySampleDrawWithoutLogging,
  } = useContext(DrawRequestContext);

  const { updatePatientLanguage } = useContext(ProfileContext);

  const {
    publicMpStepperData,
    setPublicMpFailedErrorType,
    setPublicMpToken,
    setPublicMpCode,
    recaptchaValue,
  } = useContext(PublicMpStepperDataContext);

  const {
    firstName,
    lastName,
    alternativeLastName,
    dateOfBirth,
    email,
    mobileNumber,
  } = publicMpStepperData ?? {};

  const phone = convertPhoneNumberToE164(mobileNumber ?? "");

  const handleOrderSuccess = async (data: MatchPatientNonAuthResponse) => {
    if (data?.code) {
      setPublicMpCode(data.code);
    }

    if (data?.token && dateOfBirth) {
      setPublicMpToken(data.token);

      if (data.code !== "MP_NO_ORDER_FOUND") {
        await verifySampleDrawWithoutLogging(data.token, dateOfBirth);
      }

      await updatePatientLanguage(currentLanguage, data.token);
    }

    if (data?.skipCommunicationPreferencesStep) {
      if (data.code === "MP_NO_ORDER_FOUND") {
        setScheduleScreen(DrawRequestScreens.NO_MATCH_ORDER_SCREEN);
      } else {
        setScheduleScreen(DrawRequestScreens.SCHEDULE_BLOOD_DRAW_SCREEN);
      }
    } else {
      setScheduleScreen(DrawRequestScreens.COMMUNICATIONS_PREFERENCES_SCREEN);
    }
  };

  const checkOrder = async () => {
    if (
      !firstName ||
      !lastName ||
      !dateOfBirth ||
      !phone ||
      !email ||
      !recaptchaValue
    ) {
      return;
    }

    const patientNonAuthInfoData = {
      firstName,
      lastName,
      alternativeLastName,
      dateOfBirth,
      email,
      phone,
    };

    try {
      const data = await matchPatientNonAuthRequest({
        patientNonAuthInfoData,
        recaptchaValue,
      });

      data && handleOrderSuccess(data);
    } catch (error) {
      const errorType =
        (errorMapping[error?.code] as PublicMpFailedErrorType) ||
        "MP_GENERIC_NO_MATCH_ERROR";
      setPublicMpFailedErrorType(errorType);

      if (errorType === "MP_NO_PATIENT_MATCH") {
        setScheduleScreen(DrawRequestScreens.NO_MATCH_ORDER_SCREEN);
      } else {
        setScheduleScreen(DrawRequestScreens.FAILED_SCREEN);
      }
    }
  };

  useEffect(() => {
    checkOrder();
  }, []);

  return (
    <>
      <div className="match-order">
        <div className="match-order-result">
          <Svg
            className="match-order-result-icon"
            content={DocumentsMagnifyingGlass}
          />
          <p className="match-order-result-text">
            {intl.formatMessage(messages.matchOrderLoadingText)}
          </p>
        </div>
      </div>
    </>
  );
};

export default MatchOrder;
